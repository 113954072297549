export default [
	{
		path: "/brain/ejemplares",
		name: "brain-specimens-main",
		component: () => import("@/views/brain/specimens/main.vue"),
		redirect: { name: "brain-specimens-colors" },
		meta: {
			roles: [1, 2],
		},
		children: [
			{
				path: "colores",
				name: "brain-specimens-colors",
				component: () => import("@/views/brain/specimens/views/ColourSpecimenSettings.vue"),
				meta: {
					pageTitle: "Colores",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Colores",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "enfermedades",
				name: "brain-specimens-diseases",
				component: () => import("@/views/brain/specimens/views/diseases/ListDiseasesBrain.vue"),
				meta: {
					pageTitle: "Enfermedades",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Enfermedades",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "criadores",
				name: "brain-specimens-breeder",
				component: () => import("@/views/brain/specimens/views/breeder/ListParticipantsBrain.vue"),
				meta: {
					pageTitle: "Criadores",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Criadores",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "raza",
				name: "brain-specimens-race",
				component: () => import("@/views/brain/specimens/views/Race.vue"),
				meta: {
					pageTitle: "Raza",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Raza",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "measure",
				name: "brain-specimens-measure",
				component: () => import("@/views/brain/specimens/views/Measure.vue"),
				meta: {
					pageTitle: "Medida",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Medida",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "status",
				name: "brain-specimens-status",
				component: () => import("@/views/brain/specimens/views/Status.vue"),
				meta: {
					pageTitle: "Estado",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Estados",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "days-without-topes",
				name: "brain-specimens-days-without-topes",
				component: () => import("@/views/brain/others/views/Decrest.vue"),
				props: { origin: "specimens" },
				meta: {
					pageTitle: "Dias sin topar",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Dias sin topar",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "attributes",
				name: "brain-specimens-attributes",
				component: () => import("@/views/brain/specimens/views/qualities/Attributes.vue"),
				meta: {
					pageTitle: "Cualidades y Características",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Ejemplares",
							active: true,
						},
						{
							text: "Cualidades y Características",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
		],
	},
]
