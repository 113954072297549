export default [
	{
		path: "/brain/encaste-settings",
		name: "brain-encaste-main",
		redirect: { name: "brain-encaste-colors" },
		component: () => import("@/views/brain/encaste/main.vue"),
		children: [
			{
				path: "colors",
				name: "brain-encaste-colors",
				component: () => import("@/views/brain/encaste/views/colours/ColourList.vue"),
				meta: {
					pageTitle: "Configuración Encaste",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Encaste",
							active: true,
						},
						{
							text: "Colors",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "patas",
				name: "brain-encaste-patas",
				component: () => import("@/views/brain/encaste/views/chickenlegs/ChickenlegList.vue"),
				meta: {
					pageTitle: "Configuración Encaste",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Encaste",
							active: true,
						},
						{
							text: "Patas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "motivos",
				name: "brain-encaste-reasons",
				component: () => import("@/views/brain/encaste/views/reasons/ReasonsList.vue"),
				meta: {
					pageTitle: "Configuración Encaste",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Encaste",
							active: true,
						},
						{
							text: "Motivos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "etapas",
				name: "brain-encaste-etapas",
				component: () => import("@/views/brain/encaste/views/Etapas.vue"),
				meta: {
					pageTitle: "Configuración Encaste",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Encaste",
							active: true,
						},
						{
							text: "Etapas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "temporadas",
				name: "brain-encaste-temporadas",
				component: () => import("@/views/brain/encaste/views/Temporadas.vue"),
				meta: {
					pageTitle: "Configuración Encaste",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Encaste",
							active: true,
						},
						{
							text: "Temporadas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "check-madrilla",
				name: "brain-encaste-check-madrilla",
				component: () => import("@/views/brain/others/views/Decrest.vue"),
				props: { origin: "encaste" },
				meta: {
					pageTitle: "Check Madrillas",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Encaste",
							active: true,
						},
						{
							text: "Check madrillas",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
			{
				path: "unborn-chicks-reasons",
				name: "brain-encaste-unborn-chicks-reasons",
				component: () => import("@/views/brain/encaste/views/reasons/UnbornReasons.vue"),
				meta: {
					pageTitle: "Motivos de no nacidos",
					breadcrumb: [
						{
							text: "Cerebro",
							active: false,
							to: "/brain",
						},
						{
							text: "Encaste",
							active: true,
						},
						{
							text: "Motivos de no nacidos",
							active: true,
						},
					],
					roles: [1, 2],
				},
			},
		],
	},
]
