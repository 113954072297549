<template>
	<b-overlay :show="isLoadingMortality" class="container-card-dashboard card mb-0">
		<div class="header p-2">
			<div class="d-flex align-items-center">
				<h1>Mortalidad por Mes de Nacimiento |</h1>
				<span class="text">
					Total:
					<b>{{ total || "---" }}</b>
				</span>
			</div>
		</div>
		<b-row>
			<b-col cols="12" lg="6">
				<VueApexCharts
					type="bar"
					height="400"
					:options="chartOpts"
					:series="chartData"
					class="px-1"
					@click="handleChartsClick"
				/>
			</b-col>
			<b-col cols="12" lg="6">
				<b-table-simple sticky-header="380px" class="px-4">
					<b-thead>
						<b-tr>
							<b-th class="capitalizable">Mes de nacimiento</b-th>
							<b-th class="text-center capitalizable">Muertos</b-th>
							<b-th class="text-center capitalizable">Nacidos</b-th>
							<b-th class="text-center capitalizable">%</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<template v-if="mortalityInfo.length === 0">
							<b-tr>
								<b-td colspan="2" class="text-center">No hay datos</b-td>
							</b-tr>
						</template>
						<template v-else>
							<b-tr v-for="item in mortalityInfo" :key="item.id">
								<b-td>{{ item.month_name }}</b-td>
								<b-td
									class="text-center"
									@click="item.dead_qty > 0 ? toggleModal(item) : null"
									:class="{ 'underline-hover clickable': item.dead_qty > 0 }"
								>
									{{ item.dead_qty }}
								</b-td>
								<b-td class="text-center">
									{{ item.born_qty }}
								</b-td>
								<b-td class="text-center">{{ item.dead_percentage }}%</b-td>
							</b-tr>
						</template>
					</b-tbody>
				</b-table-simple>
			</b-col>
		</b-row>
		<b-modal
			ref="mortality-details"
			:title="`${selectedMonth.month_name}: ${selectedMonth.dead_percentage}% | Muertos : ${selectedMonth.dead_qty} | Nacidos : ${selectedMonth.born_qty}`"
			size="lg"
			hide-footer
		>
			<MortalityDetails :year="year" :selectedMonth="selectedMonth" />
		</b-modal>
	</b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import MortalityDetails from "./MortalityDetails.vue"
import specimensService from "@/services/specimens.service"
import { tooltip } from "leaflet"
export default {
	components: { VueApexCharts, MortalityDetails },
	props: {
		year: Object,
	},
	data: () => ({
		chartData: [
			{
				name: "Muertes",
				data: new Array(6).fill(3),
			},
		],
		chartOpts: {
			labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"],
			colors: ["#7367f0"],
			fill: {
				type: "gradient",
				gradient: {
					shade: "light",
					type: "horizontal",
					shadeIntensity: 0.25,
					gradientToColors: undefined,
					inverseColors: true,
					opacityFrom: 0.85,
					opacityTo: 0.85,
					stops: [50, 0, 100],
				},
			},
		},
		selectedMonth: {
			dead_qty: 0,
			month: "",
			month_name: "",
		},
		total: 0,
		detailsInfo: [],
		mortalityInfo: [],
		isLoadingMortality: false,
	}),
	methods: {
		async getMortalityByMonthOfBirth() {
			this.isLoadingMortality = true
			const { data } = await specimensService.getDeadSpecimenQtyByMonthOfBirth({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
			})

			this.mortalityInfo = data
			this.total = this.mortalityInfo.reduce((acc, curr) => acc + curr.dead_qty, 0)
			this.chartData = [
				{
					name: "Muertos%",
					// data: this.mortalityInfo.map((d) => d.dead_percentage),
					data: this.mortalityInfo.map((d) => {
						return {
							x: d.month_name,
							y: d.dead_percentage,
						}
					}),
				},
			]
			this.chartOpts = {
				labels: this.mortalityInfo.map((d) => d.month_name),
				colors: ["#7367f0"],
				xaxis: { labels: { trim: true, hideOverlappingLabels: false } },
				yaxis: {
					labels: {
						formatter: function (val) {
							return val + "%"
						},
					},
				},
				tooltip: {
					enabled: true,
					y: {
						formatter: function (val) {
							return val + "%"
						},
					},
				},
			}
			this.isLoadingMortality = false
		},
		handleChartsClick(event, chartContext, config) {
			if (event.target.classList.contains("apexcharts-bar-area")) {
				const month = this.mortalityInfo[config.dataPointIndex]
				this.toggleModal(month)
			}
		},
		async toggleModal(month) {
			this.selectedMonth = month
			this.$refs["mortality-details"].toggle()
		},
	},
	mounted() {
		this.getMortalityByMonthOfBirth()
	},
	watch: {
		"year.start_date": async function () {
			await this.getMortalityByMonthOfBirth()
		},
	},
}
</script>
<style lang="scss" scoped>
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		// border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
}

.underline-hover {
	text-decoration: underline;
	transition: all 0.1s ease;
}

.underline-hover:hover {
	font-weight: bold;
	cursor: pointer;
	font-size: 1.2rem;
}
.capitalizable {
	text-transform: capitalize !important;
}
</style>
