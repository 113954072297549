<template>
	<div class="p-2">
		<b-row class="px-2 pb-1" v-if="!this.postura_id">
			<b-col cols="12" lg="7" md="7" class="mb-1"></b-col>
			<b-col cols="12" lg="3" md="3" class="mb-1">
				<div class="d-flex align-items-center">
					<b-form-input
						@keyup.enter="init"
						v-model="searchCode"
						placeholder="Buscar por codigo de lote"
					></b-form-input>
					<button @click="init" class="btn btn-primary ml-1">Buscar</button>
				</div>
			</b-col>

			<b-col cols="12" lg="2" md="2" class="mb-1">
				<b-button
					to="/encaste/lotes/crear"
					block
					variant="outline-primary"
					@click="controllerDeadChicks = true"
					:disabled="disabledEncaste"
				>
					<feather-icon icon="FeatherIcon" />
					Crear lote
				</b-button>
			</b-col>
		</b-row>
		<div class="table-responsive">
			<b-table-simple class="position-relative" sticky-header="calc(100vh - 240px)">
				<b-thead>
					<b-tr>
						<b-th :colspan="this.postura_id ? 1 : 3" class="bg-transparent border-0" />
						<b-th
							:colspan="this.postura_id ? 3 : 4"
							class="border-white text-white text-center box-primary"
						>
							HUEVOS
						</b-th>
						<b-th
							:colspan="this.postura_id ? 5 : 6"
							class="border-white text-white text-center box-primary"
						>
							POLLOS
						</b-th>
						<b-th
							v-if="!this.postura_id"
							colspan="2"
							class="border-white text-white text-center box-primary"
						>
							FECHAS
						</b-th>
					</b-tr>
					<b-tr class="text-center">
						<template v-for="(item, key) in headTable">
							<b-th
								v-if="item.visible"
								:key="key"
								:style="{ padding: 0 }"
								class="p-1"
								:class="[key == 0 ? 'item-sticky-header' : '']"
							>
								<div class="d-flex align-items-center justify-content-center">
									<img class="icon-head-posturas" :src="item.image" alt="" />
									<p class="mb-0">
										{{ item.text }}
									</p>
								</div>
							</b-th>
						</template>
					</b-tr>
				</b-thead>

				<b-tbody>
					<template v-if="lotes.length > 0">
						<b-tr v-for="(data, index) in lotes" class="text-center" :key="index">
							<b-td
								:style="{ position: 'sticky', left: '0', backgroundColor: '#fff' }"
								class="text-center"
							>
								{{ data.code }}
								<br />
								<small v-if="!postura_id" class="text-uppercase" style="font-size: 10px !important">
									{{ data.method }}
								</small>
							</b-td>

							<b-td
								@click="clickIdLote(data)"
								v-if="!postura_id"
								class="text-center text-primary font-weight-bolder cursor-pointer"
							>
								{{ data.posturas }}
							</b-td>

							<b-td class="text-center font-weight-bolder">
								<span
									:class="data.ing != '0' ? 'clickable' : ''"
									@click="data.ing != '0' ? openEggsDetailModal(data, 'ing') : null"
								>
									{{ data.ing }}
								</span>
							</b-td>

							<b-td class="text-center">
								<span
									:class="data.inf != '0' ? 'clickable' : ''"
									@click="data.inf != '0' ? openEggsDetailModal(data, 'inf') : null"
								>
									{{ data.inf }}
								</span>
							</b-td>

							<b-td class="text-center">
								<span
									:class="data.que != '0' ? 'clickable' : ''"
									@click="data.que != '0' ? openEggsDetailModal(data, 'que') : null"
								>
									{{ data.que }}
								</span>
							</b-td>

							<b-td class="text-center font-weight-bolder">
								<span
									:class="data.inc != '0' ? 'clickable' : ''"
									@click="data.inc != '0' ? openEggsDetailModal(data, 'inc') : null"
								>
									{{ data.inc }}
								</span>
							</b-td>

							<b-td v-if="!postura_id" class="text-center">
								{{ data.stage > 2 ? data.fer : "---" }}
							</b-td>

							<b-td class="text-center">
								<span
									v-if="data.stage > 3"
									:class="data.nacidos != '0' ? 'clickable' : ''"
									@click="data.nacidos != '0' ? openEggsDetailModal(data, 'nac') : null"
								>
									{{ data.nacidos }}
								</span>
								<span v-else>---</span>
							</b-td>

							<b-td class="text-center">
								<span
									v-if="data.stage > 3"
									:class="data.no_n != '0' ? 'clickable' : ''"
									@click="data.no_n != '0' ? openEggsDetailModal(data, 'no_n') : null"
								>
									{{ data.no_n }}
								</span>
								<span v-else>---</span>
							</b-td>

							<b-td class="text-center">
								{{ data.stage > 3 ? data.efe_inc : "---" }}
							</b-td>

							<b-td class="text-center font-weight-bolder">
								<!-- <p
									class="mb-0 font-weight-bolder text-info clickable"
									@click="
										;(modalAlivesDetail.info = data),
											(modalAlivesDetail.show = !modalAlivesDetail.show)
									"
								>
									{{ data.stage > 3 ? data.vivos : "---" }}
								</p> -->
								<span v-if="data.stage > 3">
									<span class="text-secondary" v-if="data.vivos == '0'">0</span>
									<span
										class="text-info clickable"
										@click="
											;(modalAlivesDetail.info = data),
												(modalAlivesDetail.show = !modalAlivesDetail.show)
										"
										v-else
									>
										{{ data.vivos }}
									</span>
								</span>
								<span class="text-secondary" v-else>---</span>
							</b-td>

							<b-td class="text-center font-weight-bolder">
								<span v-if="data.stage > 3">
									<span class="text-secondary" v-if="data.deads_lote == '0'">0</span>
									<span
										class="text-danger clickable"
										@click="
											;(modalDeadsDetail.info = data),
												(modalDeadsDetail.show = !modalDeadsDetail.show)
										"
										v-else
									>
										{{ data.deads_lote }}
									</span>
								</span>
								<span class="text-secondary" v-else>---</span>
							</b-td>

							<b-td class="text-center">
								{{ data.stage > 3 ? data.depu_total : "---" }}
							</b-td>

							<b-td v-if="!postura_id" class="text-center">
								{{ data.stage > 3 ? (data.efe ? data.efe : "---") : "---" }}
							</b-td>

							<template v-if="!postura_id">
								<b-td v-if="data.start_date" class="text-center">
									{{ data.start_date | myGlobalDayShort }}
								</b-td>

								<b-td v-else>---</b-td>
							</template>

							<template v-if="!postura_id">
								<b-td v-if="data.dob" class="text-center">
									<div>
										<div v-if="!data.dob_edit" class="d-flex align-items-center">
											<p class="mb-0">{{ data.dob }}</p>
											<div
												class="position-relative"
												v-if="
													data.stages_array
														.map((item, index) => {
															if (index == data.stages_array.length - 1) {
																return item.status_stage_lote_id
															}
														})
														.filter((it) => it != null)[0] != 3
												"
											>
												<FeatherIcon
													@click="data.dob_edit = true"
													icon="EditIcon"
													class="icon-clock cursor-pointer ml-50"
													color="orange"
												/>
											</div>
										</div>
										<div v-else>
											<date-picker
												@input="editDobLote(data.id, data.dob)"
												format="DD-MM-YYYY"
												v-model="data.dob"
												valueType="format"
												:formatter="momentFormat"
												:clearable="false"
											></date-picker>
										</div>
									</div>
								</b-td>
								<b-td v-else>---</b-td>
							</template>

							<b-td v-if="!postura_id">
								<div class="d-flex align-items-center">
									<div v-for="(stage, key) in data.stages_array" :key="key" class="position-relative">
										<StageLote
											:stage="stage"
											:stages="data.stages_array"
											:index="key"
											@open-stage="openModal(stage, data)"
										/>
										<div class="stageCommentIndicator" v-if="stage.observation"></div>
									</div>
									<FeatherIcon
										@click="clickTrackingStages(data)"
										icon="AlignJustifyIcon"
										class="cursor-pointer"
										color="orange"
										size="24"
									/>
								</div>
							</b-td>

							<b-td
								v-if="postura_id"
								class="text-center font-weight-bolder"
								:class="[data.cintillo_ala == 'COLOCADO' ? 'text-success' : 'text-warning']"
							>
								{{ data.cintillo_ala }}
							</b-td>
						</b-tr>
					</template>
					<template v-else>
						<b-tr>
							<b-td colspan="12" class="text-center">
								<p class="mb-1 mt-1 font-weight-bolder">NO HAY HUEVOS INGRESADO EN LOTES</p>
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
				<b-tfoot>
					<b-tr v-if="!postura_id" class="sticky-bottom text-center text-white">
						<b-td sticky-column class="font-weight-bolder bg-dark text-white">Total</b-td>
						<b-td class="" style="background: #666262">{{ totalResults.posturas }}</b-td>
						<b-td style="background: #666262">{{ totalResults.ingresados }}</b-td>
						<b-td style="background: #666262">{{ totalResults.infertiles }}</b-td>
						<b-td style="background: #666262">{{ totalResults.quemados }}</b-td>
						<b-td style="background: #666262">{{ totalResults.incubando }}</b-td>
						<b-td style="background: #666262">{{ totalResults.fertilidad }}</b-td>
						<b-td style="background: #666262">{{ totalResults.nacidos }}</b-td>
						<b-td style="background: #666262">{{ totalResults.no_nacidos }}</b-td>
						<b-td style="background: #666262">{{ totalResults.efe_infc }}</b-td>
						<b-td style="background: #666262">{{ totalResults.vivos }}</b-td>
						<b-td style="background: #666262">
							<span
								:class="{
									'cursor-pointer': totalResults.muertos && totalResults.muertos > 0,
								}"
								@click="openDeadsDsPie"
							>
								{{ totalResults.muertos }}
							</span>
						</b-td>

						<b-td style="background: #666262">{{ totalResults.depu_total }}</b-td>

						<b-td style="background: #666262">{{ totalResults.efectividad }}</b-td>
						<b-td class="bg-white" colspan="3"></b-td>
					</b-tr>
				</b-tfoot>
			</b-table-simple>
		</div>

		<b-modal
			v-if="modalViewInfertiles.show && [2, 3].includes(modalViewInfertiles.stage)"
			v-model="modalViewInfertiles.show"
			size="xl"
			hide-footer
			centered
			no-close-on-backdrop
			scrollable
			modal-header-close
			header-class="modal-heade-class"
		>
			<RevisionInfertile :lote="modalViewInfertiles" @refresh="refreshLotes" />
		</b-modal>

		<b-modal
			v-if="modalViewInfertiles.show && [4].includes(modalViewInfertiles.stage)"
			v-model="modalViewInfertiles.show"
			size="xl"
			hide-footer
			centered
			no-close-on-backdrop
			scrollable
			modal-header-close
			header-class="modal-heade-class"
		>
			<RevisionNacedora :lote="modalViewInfertiles" @refresh="refreshLotes" />
		</b-modal>

		<b-modal
			v-if="modalViewInfertiles.show && [5].includes(modalViewInfertiles.stage)"
			v-model="modalViewInfertiles.show"
			size="xmd"
			hide-footer
			centered
			no-close-on-backdrop
			scrollable
			modal-header-close
			header-class="modal-heade-class"
		>
			<RevisionVaccinate :lote="modalViewInfertiles" @refresh="refreshLotes" />
		</b-modal>

		<b-modal
			v-if="modalViewInfertiles.show && [6].includes(modalViewInfertiles.stage)"
			v-model="modalViewInfertiles.show"
			size="xmd"
			hide-footer
			centered
			no-close-on-backdrop
			scrollable
			modal-header-close
			header-class="modal-heade-class"
		>
			<RevisionChicks :lote="modalViewInfertiles" @refresh="refreshLotes" />
		</b-modal>

		<b-modal
			v-if="modalViewPosturasByLote.show"
			v-model="modalViewPosturasByLote.show"
			:title="modalViewPosturasByLote.title"
			size="xl"
			hide-footer
		>
			<PosturasListByLote :lote="modalViewPosturasByLote" @refresh="refreshLotes" />
		</b-modal>

		<b-modal
			v-model="modalViewTrackingStagesByLote.show"
			:title="modalViewTrackingStagesByLote.title"
			hide-footer
			size="lg"
		>
			<TrackingStagesLotes :lote="modalViewTrackingStagesByLote" />
		</b-modal>
		<DeadsDetail
			:show="modalDeadsDetail.show"
			:info="modalDeadsDetail.info"
			:season="modalDeadsDetail.season"
			:is-general="modalDeadsDetail.isGeneral"
			@closing="
				modalDeadsDetail.show = false
				modalDeadsDetail.isGeneral = false
			"
		/>
		<AlivesDetail
			:show="modalAlivesDetail.show"
			:info="modalAlivesDetail.info"
			@closing="modalAlivesDetail.show = false"
		/>
		<EggsDetailModal
			:show="EggsDetailModal.show"
			:info="EggsDetailModal.info"
			:type="EggsDetailModal.type"
			@closing="EggsDetailModal.show = false"
		/>
	</div>
</template>

<script>
import { bus } from "@/main"

import loteService from "@/services/lotes.service"
import StageLote from "./components/StageLote.vue"
import RevisionInfertile from "./modals/RevisionInfertile.vue"
import RevisionNacedora from "./modals/RevisionNacedora.vue"
import RevisionVaccinate from "./modals/RevisionVaccinate.vue"
import PosturasListByLote from "./modals/PosturasListByLote.vue"
import RevisionChicks from "./modals/RevisionChicks.vue"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import moment from "moment"
import TrackingStagesLotes from "./modals/TrackingStagesLotes.vue"
import DeadsDetail from "./modals/DeadsDetail.vue"
import AlivesDetail from "./modals/AlivesDetail.vue"
import EggsDetailModal from "./modals/EggsDetailModal.vue"

export default {
	name: "LotesList",
	components: {
		StageLote,
		RevisionInfertile,
		RevisionNacedora,
		RevisionVaccinate,
		PosturasListByLote,
		RevisionChicks,
		DatePicker,
		TrackingStagesLotes,
		DeadsDetail,
		AlivesDetail,
		EggsDetailModal,
	},
	props: {
		postura_id: {
			type: Number,
			default: null,
		},
		year: {
			type: Number,
			default: null,
		},
	},
	created() {
		bus.$on("reload-lotes-list", async () => {
			await this.init()
		})
	},
	destroyed() {
		bus.$off("reload-lotes-list")
	},
	async mounted() {
		await this.init()
	},
	computed: {
		totalNacidos() { 
			return this.lotes.reduce(function (previousValue, currentValue) {
				return previousValue + (currentValue.stage > 3 ? parseInt(currentValue.nacidos) : 0)
			}, 0)
		},
		totalIncubando(){
			return this.lotes.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue.inc)
			}, 0)
		},
		totalFertilidad() {
			let count = 0
			let sum = this.lotes.reduce((act, curr) => {
				if (curr.stage > 2) {
					count++
					let [newcurr] = curr.fer.split("%")
					return act + newcurr * 1
				}
				return act
			}, 0)
			return ((sum * 1) / count).toFixed(2) + "%"
		},

		totalEfectividad() {
			let count = 0
			let sumVivos = this.lotes.reduce((act, curr) => {
				if (curr.stage > 3) {
					count++
					let vivosSum = parseInt(curr.vivos)
					let depuSum = parseInt(curr.depu_total)
					return act + (vivosSum + depuSum)
				}
				return act
			}, 0)

			let sumNacidos = this.lotes.reduce((act, curr) => {
				if (curr.stage > 3) {
					count++
					let vivosSum = parseInt(curr.nacidos)
					return act + vivosSum
				}
				return act
			}, 0)
			return ((sumVivos * 100) / sumNacidos).toFixed(2) + "%"
		},

		totalEfeInc() {
			return (this.totalNacidos * 100 / this.totalIncubando).toFixed(2) + "%"
		}
	},
	data() {
		return {
			headTable: [
				{ text: "LOTE", value: "lote", visible: true },
				// { text: "MÉTODO", value: "metodo", visible: !this.postura_id },
				{
					text: "POS",
					value: "pos",
					visible: true,
					image: "/lotes/lote-grid-1.svg",
					visible: !this.postura_id,
				},
				{
					text: "ING",
					value: "ing",
					image: "/lotes/lote-grid-3.svg",
					visible: true,
				},
				{
					text: "INF",
					value: "inf",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "QUE",
					value: "que",
					image: "/lotes/lote-grid-2.svg",
					visible: true,
				},
				{
					text: "INC",
					value: "inc",
					image: "/lotes/lote-grid-5.svg",
					visible: true,
				},
				{
					text: "FER",
					value: "fer",
					image: "/lotes/lote-grid-8.svg",
					visible: !this.postura_id,
				},
				{
					text: "NAC",
					value: "nac",
					image: "/lotes/lote-grid-5.svg",
					visible: true,
				},
				{
					text: "NON",
					value: "no_n",
					image: "/lotes/lote-grid-6.svg",
					visible: true,
				},
				{
					text: "% INC",
					value: "efe_inc",
					image: "/lotes/lote-grid-5.svg",
					visible: true,
				},
				{
					text: "VIV",
					value: "viv",
					image: "/lotes/lote-grid-7.svg",
					visible: true,
				},
				{
					text: "MUE",
					value: "mue",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "DEP",
					value: "dep",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "EFE",
					value: "efe",
					image: "/lotes/lote-grid-8.svg",
					visible: !this.postura_id,
				},
				{
					text: "CREACION",
					value: "creacion",
					visible: !this.postura_id,
				},
				{
					text: "NACIMIENTO",
					value: "nacimiento",
					visible: !this.postura_id,
				},
				{
					text: "ETAPAS",
					value: "etapas",
					visible: !this.postura_id,
				},
				{
					text: "CINTILLOS",
					value: "cintillos",
					visible: this.postura_id,
				},
			],
			lotes: [],
			stages: [],
			modalViewInfertiles: {
				show: false,
				title: "REVISION INFERTILES/QUEMADOS",
				lote_id: null,
				code: null,
				stage: null,
				status_stage_lote_id: null,
			},
			modalViewPosturasByLote: {
				show: false,
				title: "Posturas del lote",
				lote_id: null,
				code: null,
				stage: null,
			},
			modalViewTrackingStagesByLote: {
				show: false,
				title: null,
				lote_id: null,
				code: null,
				stage: null,
			},
			dobUpdateLote: "",
			momentFormat: {
				stringify: (date) => {
					return date ? moment(date).format("DD-MM-YYYY") : ""
				},
				parse: (value) => {
					return value ? moment(value, "DD-MM-YYYY").toDate() : null
				},
				getWeek: (date) => {
					return // a number
				},
			},
			searchCode: null,
			winScreen: screen.width,
			totalResults: {},
			totalPosturas: 0,
			modalDeadsDetail: { show: false, info: {}, isGeneral: false, season: null },
			modalAlivesDetail: { show: false, info: {} },
			EggsDetailModal: { show: false, info: {}, type: "" },
		}
	},
	methods: {
		openEggsDetailModal(data, type) {
			this.EggsDetailModal.info = data
			this.EggsDetailModal.type = type
			this.EggsDetailModal.show = true
		},
		async init() {
			this.isPreloading()
			if (this.postura_id) {
				const lotes = await loteService.getLotesByPostura({
					postura_id: this.postura_id,
				})
				this.isPreloading(false)
				this.lotes = lotes
			} else {
				let season = this.seasonFromId(this.year)
				const { lotes, posturas } = await loteService.getLotes({
					search: this.searchCode,
					start_date: season.start,
					end_date: season.end,
					// year: this.year ? season.season.slice(2, 4) : null,
				})
				this.isPreloading(false)

				this.lotes = lotes.map((item) => {
					return {
						...item,
						dob_edit: false,
					}
				})

				this.totalResults = {
					posturas: posturas,
					ingresados: lotes.reduce((a, b) => parseInt(a) + parseInt(b.ing), 0),
					infertiles: lotes.reduce(function (previousValue, currentValue) {
						return previousValue + (currentValue.stage > 1 ? parseInt(currentValue.inf) : 0)
					}, 0),
					quemados: lotes.reduce(function (previousValue, currentValue) {
						return previousValue + (currentValue.stage > 1 ? parseInt(currentValue.que) : 0)
					}, 0),
					incubando: this.totalIncubando,
					fertilidad: this.totalFertilidad,
					nacidos: this.totalNacidos,
					no_nacidos: lotes.reduce(function (previousValue, currentValue) {
						return previousValue + (currentValue.stage > 3 ? parseInt(currentValue.no_n) : 0)
					}, 0),
					efe_infc: this.totalEfeInc,
					vivos: lotes.reduce(function (previousValue, currentValue) {
						return previousValue + (currentValue.stage > 3 ? parseInt(currentValue.vivos) : 0)
					}, 0),
					muertos: lotes.reduce(function (previousValue, currentValue) {
						return previousValue + (currentValue.stage > 3 ? parseInt(currentValue.deads_lote) : 0)
					}, 0),
					depu_total: lotes.reduce(function (previousValue, currentValue) {
						return previousValue + (currentValue.stage > 3 ? parseInt(currentValue.depu_total) : 0)
					}, 0),
					efectividad: this.totalEfectividad,
				}
			}
		},
		openDeadsDsPie() {
			let season = this.seasonFromId(this.year)
			this.modalDeadsDetail.isGeneral = true
			this.modalDeadsDetail.info = { deads_lote: this.totalResults.muertos }
			this.modalDeadsDetail.season = { start: season.start, end: season.end }
			this.modalDeadsDetail.show = true
		},
		openModal(stage, lote) {
			this.modalViewInfertiles.show = true
			this.modalViewInfertiles.id = lote.id
			this.modalViewInfertiles.code = lote.code
			this.modalViewInfertiles.status_stage_lote_id = stage.status_stage_lote_id

			switch (stage.lotes_stage_order) {
				case 2:
					this.modalViewInfertiles.title = "REVISION INFERTILES/QUEMADOS"

					this.modalViewInfertiles.stage = stage.lotes_stage_order

					break
				case 3:
					this.modalViewInfertiles.title = "REVISION INFERTILES/QUEMADOS (NACEDORA)"
					this.modalViewInfertiles.stage = stage.lotes_stage_order
					break
				case 4:
					this.modalViewInfertiles.title = "REVISION DE NACIDOS / CINTILLO PATA"
					this.modalViewInfertiles.stage = stage.lotes_stage_order
					break
				case 5:
					this.modalViewInfertiles.title = "VACUNACION: VIRUELA + TRIPLE AVIAR"
					this.modalViewInfertiles.stage = stage.lotes_stage_order
					break
				case 6:
					this.modalViewInfertiles.title = "INGRESO DE CINTILLO ALA"
					this.modalViewInfertiles.stage = stage.lotes_stage_order
					break

				default:
					this.closeModal()
					break
			}
		},
		async refreshLotes(hasChanged = true) {
			if (hasChanged) {
				this.closeModal()
			}
			await this.init()
		},
		closeModal() {
			this.modalViewInfertiles = {
				show: false,
				title: "REVISION INFERTILES/QUEMADOS",
				lote_id: null,
				code: null,
				stage: null,
				status_stage_lote_id: null,
			}
		},
		clickIdLote(lote) {
			this.modalViewPosturasByLote.show = true
			this.modalViewPosturasByLote.lote_id = lote.id
			this.modalViewPosturasByLote.code = lote.code
			this.modalViewPosturasByLote.stage = lote.stage
			this.modalViewPosturasByLote.title = `LOTE: ${lote.code} - DETALLE DE POSTURAS`
		},
		async editDobLote(id, dob) {
			this.isPreloading()
			if (dob) {
				const resp = await loteService.updateDobByLoteId({
					dob: `${dob.split("-")[2]}-${dob.split("-")[1]}-${dob.split("-")[0]}`,
					lote_id: id,
				})
				await this.init()
			}
			this.isPreloading(false)
		},
		clickTrackingStages(lote) {
			this.modalViewTrackingStagesByLote.show = true
			this.modalViewTrackingStagesByLote.lote_id = lote.id
			this.modalViewTrackingStagesByLote.code = lote.code
			this.modalViewTrackingStagesByLote.title = `LOTE: ${lote.code} - DETALLE DE TRACKING`
		},
	},
	watch: {
		async year() {
			await this.init()
		},
	},
}
</script>

<style lang="scss">
.icon-clock {
	position: absolute;
	top: -9px;
	right: -20px;
}
.item-sticky-header {
	z-index: 3 !important;
	position: sticky;
	left: 0;
	// background: red;
}
.modal-heade-class {
	background: transparent !important;
	height: 0 !important;
	position: relative;
	padding: 0;
	padding-top: 0.8rem;
	.close {
		position: absolute;
		top: 0;
		right: 0.8rem !important;
		z-index: 1;
	}
}
.sticky-lote-code {
	position: sticky;
	z-index: 1;
	left: 0;
	background: var(--dark-alpha);
}

.stageCommentIndicator {
	position: absolute;
	top: 0;
	right: 0.8rem;
	width: 1rem;
	aspect-ratio: 1;
	background: var(--primary);
	border: 2px solid #fff;
	border-radius: 50%;
}

.sticky-bottom {
	position: sticky;
	bottom: 0;
	z-index: 1020; /* Ajusta el índice z según sea necesario */
}
</style>
