<template>
	<div class="container-dashboard-count-padrillos-and-madrillas">
		<div
			v-for="(item, key) in counters"
			:key="key"
			class="card container-card-dashboard mb-0 card-dashboard-count-specimen"
		>
			<h3>{{ item.name }}</h3>
			<div
				style="display: flex; align-items: center; justify-content: center; gap: 1rem; cursor: pointer"
				@click="showModalDetails(`Detalle por ${item.name}`, item.name)"
			>
				<p class="text-primary">
					{{ item.count }}
				</p>
				<FeatherIcon icon="SearchIcon" class="icon" color="#7367F0" size="25" />
			</div>
		</div>

		<b-modal v-model="modalDetails.show" :title="modalDetails.title" size="xmd" hide-footer scrollable>
			<SpecimensWithPosturas :category="modalDetails.category" :start_date="start_date" :end_date="end_date" />
		</b-modal>
	</div>
</template>

<script>
import encasteDashboardService from "@/services/encaste/encasteReports.service"
import SpecimensWithPosturas from "@/views/amg/encaste/dashboard-encaste/modal/SpecimensWithPosturas.vue"

export default {
	name: "DashboardCountPadrillosAndMadrillas",
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	components: {
		SpecimensWithPosturas,
	},
	data() {
		return {
			modalDetails: {
				show: false,
				title: null,
				category: null,
			},
			counters: [],
		}
	},
	async mounted() {
		await this.init()
	},
	methods: {
		showModalDetails(title, category) {
			this.modalDetails.show = true
			this.modalDetails.title = title
			// console.log('category', category);
			this.modalDetails.category = category == "MADRILLAS" ? 2 : 1
		},
		async init() {
			const { count: resp } = await encasteDashboardService.getCountPadrillosAndMadrillas({
				start_date: this.start_date,
				end_date: this.end_date,
			})
			this.counters = Object.entries(resp).map((item) => {
				return {
					name: item[0].toLocaleUpperCase(),
					count: item[1],
				}
			})
		},
	},
	watch: {
		start_date() {
			this.init()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-dashboard-count-padrillos-and-madrillas {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (min-width: 1400px) {
		grid-template-columns: 1fr 1fr;
	}
	.card-dashboard-count-specimen {
		display: flex;
		align-items: center;
		padding: 1.5rem;
		justify-content: flex-start;
		// min-height: 240px;

		h3 {
			color: #7367f0;
			font-size: 1.6rem;
			font-weight: bolder;
		}
		p {
			flex: 1;
			font-size: 1.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bolder;
			cursor: pointer;
			margin: 1rem 0 !important;
		}
	}
}
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		h1 {
			color: #6d6d6d;
			font-weight: bolder;
			margin-bottom: 0;
		}
	}
}
</style>
