<template>
	<b-modal
		ref="attributes-modal"
		:title="`${info.category.description} | ${info.type === 1 ? 'Cualidades' : 'Características'}`"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xsmd"
		hide-footer
	>
		<div>
			<b-overlay :show="isLoading">
				<div>
					<div class="d-flex align-items-center justify-content-end mb-1">
						<b-button @click="creationModal.show = true" variant="success">
							<FeatherIcon icon="PlusIcon" size="16" />
						</b-button>
					</div>

					<b-table-simple sticky-header="60vh">
						<b-thead class="text-center">
							<b-tr>
								<b-th>Nombre</b-th>
								<b-th>Activo</b-th>
								<b-th>Acciones</b-th>
							</b-tr>
						</b-thead>
						<b-tbody class="text-center">
							<b-tr v-for="data in attributes" :key="data.id">
								<b-td>{{ data.name }}</b-td>
								<b-td>
									<b-badge pill :variant="'light-' + (data.is_active === 1 ? 'success' : 'danger')">
										{{ data.is_active === 1 ? "SÍ" : "NO" }}
									</b-badge>
									<b-form-checkbox
										v-model="data.active"
										:true-value="1"
										:false-value="0"
										name="check-button"
										switch
										class="mt-1 text-center"
										@change="updateStatus(data)"
										style="margin-left: 0.5rem"
									/>
								</b-td>
								<b-td>
									<div class="d-flex justify-content-center" style="gap: 1rem">
										<feather-icon
											size="20"
											icon="EditIcon"
											class="text-primary cursor-pointer"
											@click="openEdit(data)"
										/>

										<feather-icon
											size="20"
											icon="TrashIcon"
											class="text-danger cursor-pointer"
											@click="remove(data)"
										/>
									</div>
								</b-td>
							</b-tr>
							<b-tr v-if="attributes.length == 0">
								<b-td colspan="3">
									<p class="mb-0">No hay registros por mostrar</p>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>
			</b-overlay>

			<b-modal
				centered
				v-model="creationModal.show"
				:title="`${creationModal.editing ? 'Actualizando' : 'Creando nueva'} ${
					info.type === 1 ? 'cualidad' : 'caracteristica'
				}`"
				@hidden="creationModal.show = false"
			>
				<b-overlay :show="creationModal.loading">
					<ValidationObserver ref="form">
						<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
							<b-form-group label="Nombre">
								<b-form-input
									:class="{ 'border-danger': errors[0] }"
									placeholder="Ingrese el nombre"
									v-model="creationModal.name"
								/>
							</b-form-group>
						</ValidationProvider>
					</ValidationObserver>
				</b-overlay>
				<template #modal-footer>
					<b-button variant="info" @click="edit" v-if="creationModal.editing">Actualizar</b-button>
					<b-button variant="success" @click="create" v-else>Guardar</b-button>
				</template>
			</b-modal>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import qualitiesService from "@/views/brain/specimens/views/qualities/services/qualities.services.js"

export default {
	components: {},
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			attributes: [],
			creationModal: {
				show: false,
				loading: false,
				name: null,
				oldName: null,
				id: null,
				editing: false,
			},
		}
	},
	async created() {
		await this.getAttributes()
	},

	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},

		async getAttributes() {
			this.isPreloading()
			try {
				const { data } = await qualitiesService.getQualities({
					is_active: null,
					specimen_category_id: this.info.category.id,
					type: this.info.type,
				})

				this.attributes = data.data.map((quality) => ({
					...quality,
					active: quality.is_active === 1 ? true : false,
				}))
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		openEdit(reason) {
			this.creationModal = {
				show: true,
				loading: false,
				name: reason.name,
				oldName: reason.name,
				id: reason.id,
				editing: true,
			}
		},
		clearCreation() {
			this.creationModal = {
				show: false,
				loading: false,
				name: null,
				oldName: null,
				id: null,
				editing: false,
			}
		},
		async create() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se creara una nueva ${this.info.type === 1 ? "cualidad" : "característica"}: ${
					this.creationModal.name
				}`,
			})
			if (!isConfirmed) return

			this.isPreloading()
			try {
				const res = await qualitiesService.storeQuality({
					name: this.creationModal.name,
					specimen_category_id: this.info.category.id,
					type: this.info.type,
				})
				console.log(res)
				this.clearCreation()
				this.getAttributes()
				this.$emit("refresh")
				if (res.status === true) {
					this.showSuccessToast(res.message)
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async edit() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se actualizara la ${this.info.type === 1 ? "cualidad" : "característica"}: ${
					this.creationModal.oldName
				}`,
			})
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const res = await qualitiesService.editQuality({
					id: this.creationModal.id,
					name: this.creationModal.name,
				})
				this.clearCreation()
				this.getAttributes()
				this.showSuccessToast(res.data.message)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async remove(attribute) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se eliminará la ${this.info.type === 1 ? "cualidad" : "característica"} ${attribute.name}`,
			})
			if (!isConfirmed) return

			this.isPreloading()

			try {
				await qualitiesService.deleteQuality({ id: attribute.id })

				this.getAttributes()
				this.$emit("refresh")
				this.showSuccessToast("La cualidad fue eliminada correctamente.")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async updateStatus(attribute) {
			console.log(attribute)

			const { isConfirmed } = await this.showConfirmSwal({
				title: `¿Estás seguro de que quieres ${attribute.active ? "activar" : "desactivar"} el estado de la ${
					this.info.type === 1 ? "cualidad" : "característica"
				} ${attribute.name}?`,
				text: "",
			})
			if (!isConfirmed) {
				attribute.active = !attribute.active
				return
			}
			this.isPreloading()

			try {
				const { data } = await qualitiesService.updateStatus(attribute.id)
				this.getAttributes()
				this.showSuccessToast(data.message)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},

	async mounted() {
		this.toggleModal("attributes-modal")
	},
}
</script>

<style lang="scss" scoped></style>
