<template>
	<div>
		<b-modal
			no-close-on-backdrop
			hide-footer
			size="lg"
			title="Descreste Masivo"
			v-model="isActive"
			@hidden="handleHidden"
			body-class="p-0 m-0"
		>
			<b-card class="mb-0">
				<div class="d-flex justify-content-between">
					<b-form-group label="Placa del Ejemplar" class="w-100">
						<b-input-group>
							<b-form-input
								:class="{ 'is-invalid': errorMessage }"
								v-model="specimenPlate"
								placeholder="Ingrese placa"
								@keyup.enter="searchSpecimenByPlate"
							></b-form-input>
							<b-input-group-append>
								<b-button variant="outline-primary" @click="searchSpecimenByPlate">
									<feather-icon icon="SearchIcon" />
								</b-button>
							</b-input-group-append>
							<b-input-group-append>
								<b-button
									@click="showTracking = true"
									variant="primary"
									v-b-tooltip.hover.top="'Tracking de descreste'"
								>
									<feather-icon icon="ListIcon" />
								</b-button>
							</b-input-group-append>
						</b-input-group>
						<small class="text-danger" v-if="errorMessage">{{ errorMessage }}</small>
					</b-form-group>
				</div>
				<div class="d-flex flex-wrap">
					<div v-for="specimen in selectedSpecimens" :key="specimen.id" class="d-flex align-items-center m-1">
						<SpecimenPlate :specimen="specimen" />
						<b-button @click="removeSpecimen(specimen.id)" variant="danger">
							<feather-icon icon="TrashIcon" />
						</b-button>
					</div>
				</div>
				<div class="w-100 d-flex align-items-center justify-content-end">
					<b-button
						class="d-flex align-items-center justify-content-center"
						variant="primary"
						:disabled="selectedSpecimens.length === 0"
						@click="masiveDecrest"
					>
						<FeatherIcon icon="ScissorsIcon" size="18" class="mr-50" />
						Descrestar
					</b-button>
				</div>
			</b-card>
		</b-modal>
		<DecrestationTrackingModal v-if="showTracking" @closeModal="showTracking = false" />
	</div>
</template>

<script>
import lodash from "lodash"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import decrestService from "@/services/header/decrest.service"
import DecrestationTrackingModal from "@/views/amg/header-bookmarks/decrest/DecrestationTrackingModal.vue"
import { select } from "d3"
export default {
	components: { SpecimenPlate, DecrestationTrackingModal },
	props: { show: Boolean },
	data() {
		return {
			specimenPlate: "",
			specimenPlateError: false,
			isActive: false,
			selectedSpecimens: [],
			errorMessage: null,
			showTracking: false,
		}
	},
	watch: {
		show: {
			immediate: true,
			handler(val) {
				this.isActive = val
			},
		},
	},
	methods: {
		getSpecimenPlates() {
			return this.selectedSpecimens.map((specimen) => specimen.plate).join(", ")
		},
		async masiveDecrest() {
			try {
				const resultLength = this.selectedSpecimens.length
				const specimenPlates = this.getSpecimenPlates()
				const { isConfirmed } = await this.showConfirmSwal({
					title: "¿Estás seguro de descrestar estos ejemplares?",
					html: `
						<div>
							<p><strong>Placas:</strong> ${specimenPlates}</p>
							<p><strong>Cantidad:</strong> ${resultLength}</p>
						</div>
					`,
				})
				if (!isConfirmed) return
				this.isPreloading()
				const { message } = await decrestService.masiveDecrest(
					this.selectedSpecimens.map((specimen) => specimen.id)
				)
				this.showToast("success", "top-right", "Éxito", "CheckIcon", message)
				this.selectedSpecimens = []
				this.handleHidden()
			} catch (error) {
				console.log(error)
				this.showToast("danger", "top-right", "Error", "AlertIcon", error.message)
			} finally {
				this.isPreloading(false)
			}
		},
		handleHidden() {
			this.specimenPlate = ""
			this.isActive = false
			this.$emit("closing")
		},
		removeSpecimen(id) {
			this.selectedSpecimens = this.selectedSpecimens.filter((item) => item.id !== id)
		},
		async searchSpecimenByPlate() {
			this.errorMessage = null
			try {
				if (!this.specimenPlate) return
				if (this.selectedSpecimens.some((specimen) => specimen.plate === this.specimenPlate)) {
					this.errorMessage = `Placa ${this.specimenPlate} ya fue agregada`
					this.showToast("danger", "top-right", "Error", "AlertIcon", this.errorMessage)
					return
				}
				const { data } = await decrestService.getSpecimenByPlate(this.specimenPlate)
				const { id, plate } = data
				if (!id) {
					this.errorMessage = `Placa no disponible para decrestar`
					this.showToast("danger", "top-right", "Error", "AlertIcon", this.errorMessage)
					return
				}
				this.selectedSpecimens.push(data)
				this.specimenPlate = ""
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
