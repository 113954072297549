<template>
	<b-modal
		hide-footer
		centered
		no-close-on-backdrop
		size="xlg"
		v-model="isActive"
		@hidden="handleHidden"
		title="Padrillos dispuestos"
		title-class="w-100 d-flex justify-content-between"
	>
		<div>
			<b-row class="mt-2">
				<b-col cols="0" xl="6"></b-col>
				<b-col cols="12" xl="6" class="d-flex" style="gap: 12px">
					<b-input-group>
						<b-input-group-prepend>
							<b-button class="btn-icon" variant="outline-secondary" disabled>
								<feather-icon icon="SearchIcon" />
							</b-button>
						</b-input-group-prepend>
						<b-form-input
							placeholder="Buscar placa o alias..."
							v-model="searchFilter"
							@keypress.enter="fetchSpecimensWithoutPostures"
						/>
					</b-input-group>
					<div class="d-flex" style="gap: 8px">
						<b-button variant="primary" @click="fetchSpecimensWithoutPostures">Buscar</b-button>
						<b-button class="btn-icon" variant="outline-secondary">
							<feather-icon icon="RefreshCcwIcon" @click="clearFilters" />
						</b-button>
					</div>
				</b-col>
			</b-row>
			<b-table-simple responsive sticky-header="60vh" class="cst-table mt-2" v-if="!loading">
				<b-thead class="cst-head text-nowrap text-center">
					<b-tr>
						<b-th class="bg-white border-0"></b-th>
						<b-th class="bg-primary text-white roundedTop" colspan="3">Descanso</b-th>
						<b-th class="bg-white border-0"></b-th>
					</b-tr>
					<b-tr class="text-center">
						<b-th class="text-center px-2 align-middle">Padrillo</b-th>
						<!--  -->
						<b-th class="text-center px-2 align-middle">Inicio</b-th>
						<b-th class="text-center px-2 align-middle">Tiempo en descanso</b-th>
						<b-th class="text-center px-2 align-middle">Enviado por</b-th>
						<b-th class="text-center px-2 align-middle">Tracking</b-th>
						<b-th class="text-center px-2 align-middle">Acciones</b-th>
					</b-tr>
				</b-thead>
				<TransitionGroup tag="tbody" role="table">
					<b-tr class="text-center row-table-posture" v-for="posture in postures" :key="posture.id">
						<b-th class="text-center py-50 px-2">
							<SpecimenPlate
								:specimen="{
									id: posture.specimen.id,
									plate: posture.specimen.plate,
									alias: posture.specimen.alias,
									thumb: posture.specimen.thumb,
									image: posture.specimen.url,
								}"
							/>
						</b-th>
						<b-th>
							<span v-if="posture.start_date">
								{{ posture.start_date | myGlobalDayShort }}
							</span>
							<span v-else>---</span>
						</b-th>
						<b-th>
							<span v-if="posture.days_in_rest != null">{{ posture.days_in_rest }} dia(s)</span>
							<span v-else>---</span>
						</b-th>
						<b-th>
							<span v-if="posture.created_by">{{ posture.created_by }}</span>
							<span v-else>---</span>
						</b-th>
						<b-th>
							<div class="d-flex justify-content-center gap-x-2">
								<!-- <feather-icon
										 v-b-tooltip.hover
										 title="Agregar postura"
										 icon="PlusCircleIcon"
										 size="24"
										 class="cursor-pointer text-primary"
										 @click="handleCreateNewPosture(posture.specimen)"
									 /> -->
								<feather-icon
									v-b-tooltip.hover
									title="Tracking"
									icon="ListIcon"
									size="24"
									class="cursor-pointer text-primary"
									@click="
										;(padrilloAvailable.info = posture),
											(padrilloAvailable.show = !padrilloAvailable.show)
									"
								/>
							</div>
						</b-th>
						<b-td class="text-center">
							<div class="d-flex justify-content-center">
								<b-button
									class="btn-sm"
									variant="primary"
									@click="openModalPosturaPad(posture.specimen)"
									v-b-tooltip.hover.top="'Agregar Postura'"
								>
									<FeatherIcon icon="PlusIcon" size="20" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
				</TransitionGroup>
			</b-table-simple>
			<div v-else class="mt-2">
				<b-skeleton-table :rows="8" :columns="3" :table-props="{ bordered: true, striped: true }" />
			</div>
			<p class="text-center p-3" v-if="postures.length === 0">No hay registros para mostrar</p>
			<b-row class="mt-2">
				<b-col cols="6" class="d-flex align-items-center pagination-label">
					<p style="margin: 0 0 0 1.2rem">
						Mostrando {{ this.from }} a {{ this.to }} de {{ this.total }} ejemplares disponibles
					</p>
				</b-col>
				<b-col cols="6" class="d-flex justify-content-end">
					<b-pagination
						@input="fetchSpecimensWithoutPostures"
						v-model="currentPage"
						:total-rows="this.total"
						:per-page="20"
						first-number
						last-number
						align="right"
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</div>
		<AssignMadrillasModal
			:show="assignMadrillas.show"
			:info="assignMadrillas.info"
			@closing="assignMadrillas.show = false"
			@refresh="fetchSpecimensWithoutPostures"
		/>
		<PadrilloAvailableTracking
			:show="padrilloAvailable.show"
			:info="padrilloAvailable.info"
			@closing="padrilloAvailable.show = false"
		/>
		<AddPosturaWithPadrillo
			v-if="openModalPosturaPadrillo"
			:madrillaController="true"
			:padrillo="padrilloSelected"
			@hidden="openModalPosturaPadrillo = false"
			@refresh="handleRefresh"
		/>
	</b-modal>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import SpecimenAge from "@/components/specimens/SpecimenAge.vue"
import specimenService from "@/services/specimens.service"
import AssignMadrillasModal from "./AssignMadrillasModal.vue"
import PadrilloAvailableTracking from "./PadrilloAvailableTracking.vue"
import AddPosturaWithPadrillo from "@/views/amg/encaste/posturas/modals/AddPosturaWithPadrillo.vue"

export default {
	name: "AddPosturaActiveTask",
	components: {
		SpecimenPlate,
		SpecimenAge,
		AssignMadrillasModal,
		PadrilloAvailableTracking,
		AddPosturaWithPadrillo,
	},
	props: {
		show: Boolean,
	},
	data: () => ({
		postures: [
			{
				specimen: {},
			},
		],
		loading: true,
		isActive: false,
		modalViewAddEggsToAir: {
			show: false,
			title: "Agregar huevo al aire",
			eggs: 1,
			data: null,
		},
		currentPage: 1,
		from: null,
		total: null,
		to: null,
		perpage: null,
		padActive: 1,
		roosterGender: 1,
		searchFilter: null,
		assignMadrillas: { show: false, info: {} },
		//
		season: null,
		padrilloAvailable: { show: false, info: {} },
		openModalPosturaPadrillo: false,
		padrilloSelected: null,
	}),
	methods: {
		handleRefresh() {
			this.fetchSpecimensWithoutPostures()
			this.$emit("refresh")
		},
		openModalPosturaPad(item) {
			this.padrilloSelected = item
			this.openModalPosturaPadrillo = true
		},
		async fetchSpecimensWithoutPostures() {
			this.loading = true
			const { data } = await specimenService.getRestingPadrillos({
				page: this.currentPage,
				perpage: 20,
				search: this.searchFilter,
			})
			this.currentPage = data.current_page
			this.from = data.from
			this.total = data.total
			this.to = data.to
			this.postures = data.data.map((it) => {
				return {
					...it,
					eggs_in_the_air: it.eggs_in_the_air || 0,
				}
			})
			this.loading = false
		},
		clearFilters() {
			this.searchFilter = ""
			this.fetchSpecimensWithoutPostures()
		},
		handleHidden() {
			this.$emit("closing")
		},
		handleCreateNewPosture(specimen) {
			this.assignMadrillas.info = specimen
			this.assignMadrillas.show = !this.assignMadrillas.show
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.fetchSpecimensWithoutPostures()
			}
		},
	},
}
</script>

<style>
.roundedTop {
	border: 2px solid white;
	border-radius: 1rem 1rem 0 0;
}
</style>
