<template>
	<div>
		<div class="d-flex">
			<div class="dseCard__head dseCard__head--wfull">Pollos Nacidos Por Sexo</div>
		</div>
		<div class="dseCard__body dseCard__body--wfull position-relative pt-2">
			<div :key="keyPie">
				<apexchart
					type="pie"
					style="margin-top: 24px"
					:height="300"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</div>

			<div
				class="position-absolute d-flex align-items-center justify-content-between"
				style="top: 1rem; left: 1rem; width: 90%"
			>
				<b-form-select
					class="cursor-pointer text-primary"
					size="sm"
					style="width: 180px"
					label="season"
					v-model="season"
					:options="seasons.map((opt) => ({ value: opt.id, text: opt.season }))"
					@input="setSeasons($event)"
				></b-form-select>
			</div>
		</div>

		<ChildsBySexModal
			v-if="showDetail.show"
			:start_date="start_date"
			:end_date="end_date"
			:info="showDetail.info"
			@closing="showDetail.show = false"
		/>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import encasteDashboardService from "@/services/encaste/encasteReports.service"
import ChildsBySexModal from "@/views/amg/specimens/components/ChildsBySexModal.vue"

export default {
	name: "DashboardUnborn",
	components: {
		apexchart: VueApexCharts,
		ChildsBySexModal,
	},
	props: {
		specimen: Object,
	},
	data() {
		return {
			category: 1,

			series: [],

			chartOptions: {
				chart: {
					type: "pie",

					events: {
						dataPointSelection: (event, chartContext, config) => {
							const clickedIndex = config.dataPointIndex
							const chickSex = this.chartOptions.labels[clickedIndex]
							const value = this.series[clickedIndex]

							this.showDetail.info = {
								padrillo_id: this.specimen.id,
								gender: chickSex,
								total: value,
							}
							this.showDetail.show = true
						},
					},
				},
				colors: ["#736aea", "#b9b4f4", "#3f3b81"],
				labels: ["MACHOS", "HEMBRAS", "SIN ASIGNAR"],
				// labels: [],

				legend: {
					show: true,
					position: "bottom",
					formatter: (seriesName, opts) => {
						// Combina las etiquetas con las cantidades
						const value = opts.w.globals.series[opts.seriesIndex]
						return `${seriesName} (${value})`
					},
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"],
						opacity: 0.5,
					},
				},
			},

			showDetail: {
				info: null,
				show: false,
			},
			keyPie: 0,
			seasons2: [{ value: 0, text: `Todos los años` }],
			start_date: null,
			end_date: null,
			season: null,
		}
	},
	async mounted() {
		if (!this.seasons.some((season) => season.id === 0)) {
			this.seasons.unshift({
				id: 0,
				season: "Todas las temporadas",
				start: null,
				end: null,
			})
		}

		this.season = 0
		this.setSeasons(0)
		await this.initChart()
	},
	methods: {
		setSeasons(id) {
			const { start, end } = this.seasons.find((season) => season.id == id)
			this.start_date = start
			this.end_date = end
		},

		async initChart() {
			this.isPreloading()

			try {
				const res = await encasteDashboardService.getChicksBySexReport({
					start_date: this.season === 0 ? null : this.start_date,
					end_date: this.season === 0 ? null : this.end_date,
					padrillo_id: this.specimen.id,
				})

				const malesData = res.data.find((item) => item.gender === "MACHO")
				const femalesData = res.data.find((item) => item.gender === "HEMBRA")
				const unassignedData = res.data.find((item) => item.gender === "SIN ASIGNAR")

				this.series = [
					malesData ? malesData.quantity : 0,
					femalesData ? femalesData.quantity : 0,
					unassignedData ? unassignedData.quantity : 0,
				]

				// this.chartOptions.labels = [
				// 	`MACHOS (${machosData.quantity})`,
				// 	`HEMBRAS (${hembrasData.quantity})`,
				// 	`SIN ASIGNAR (${sinAsignarData.quantity})`,
				// ]

				this.keyPie = this.keyPie + 1
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		async start_date() {
			await this.initChart()
		},
	},
}
</script>

<style lang="scss" scoped>
.item-count {
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem 0.4rem;
	border-radius: 4px;
	width: auto;
	margin-right: 0.5rem;

	@media screen and (min-width: 600px) {
		padding: 0.5rem 1rem;
		margin-top: 2rem;
	}
	p {
		font-weight: bold;
		font-size: 12px;
		text-align: center;
	}

	&:first-child {
		background-color: #dae1ff;
		color: #466aff;
	}

	&:last-child {
		background-color: #fed9ff;
		color: #f941ff;
	}
}
</style>
