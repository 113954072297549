<template>
	<b-modal
		ref="modal-childs-by-sex"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="lg"
		hide-footer
	>
		<template #modal-title>
			Detalle de Pollos Nacidos Por Sexo -
			<span class="font-weight-bold" :style="{ color: colorsByGender[info.gender] }">{{ info.gender }}</span>
		</template>
		<div>
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} posturas
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
					<label>Mostrar</label>
					<v-select
						v-model="pagination.perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
						style="width: 90px"
					/>
					<label class="mr-2">posturas</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
				</b-col>
			</b-row>
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header" style="white-space: nowrap">
					<b-th class="text-center">Postura</b-th>
					<b-th class="text-center">Madrilla</b-th>
					<b-th class="text-center">Pollos Nacidos</b-th>
				</b-thead>
				<b-tbody>
					<template v-if="chicks.length > 0" v-for="(chick, index) in chicks">
						<b-tr :key="index">
							<b-td class="text-center">
								<span>{{ chick.code }}</span>
							</b-td>
							<b-td class="text-center">
								<SpecimenPlate :specimen="chick.madrilla" />
							</b-td>
							<b-td class="text-center">
								<span>{{ chick.quantity }}</span>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="chicks.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
				<b-tfoot class="sticky-footer">
					<b-tr>
						<b-td></b-td>
						<b-td>SUBTOTAL:</b-td>
						<b-td class="text-center">{{ subTotal }}</b-td>
					</b-tr>
					<b-tr>
						<b-td></b-td>
						<b-td>TOTAL:</b-td>
						<b-td class="text-center">{{ info.total }}</b-td>
					</b-tr>
				</b-tfoot>
			</b-table-simple>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import encasteDashboardService from "@/services/encaste/encasteReports.service"

export default {
	components: { SpecimenPlate },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		start_date: String,
		end_date: String,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			chicks: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
			colorsByGender: {
				["MACHOS"]: "#4f71ff",
				["HEMBRAS"]: "#f941ff",
				["SIN ASIGNAR"]: "#b5b5b5",
			},
		}
	},
	async created() {
		await this.getDetails()
	},
	computed: {
		subTotal() {
			return this.chicks.reduce((acc, cur) => acc + cur.quantity, 0)
		},
	},
	methods: {
		async refresh() {
			await this.getDetails()
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},

		async getDetails() {
			this.isPreloading()
			try {
				const { data } = await encasteDashboardService.getChildsBySexDetails({
					start_date: this.start_date,
					end_date: this.end_date,
					padrillo_id: this.info.padrillo_id,
					gender:
						this.info.gender === "MACHOS"
							? "MACHO"
							: this.info.gender === "HEMBRAS"
							? "HEMBRA"
							: "SIN ASIGNAR",
					per_page: this.pagination.perPage,
					page: this.pagination.curPage,
				})
				this.chicks = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getDetails()
		},
		"pagination.perPage": async function () {
			await this.getDetails()
		},
	},
	async mounted() {
		this.toggleModal("modal-childs-by-sex")
	},
}
</script>

<style>
.sticky-footer {
	position: sticky;
	bottom: 0;
	background-color: #f8f9fa;
	border-top: 1px solid #dee2e6;
}
</style>
