<template>
	<div>
		<b-overlay :show="isLoading">
			<b-card class="p-1" no-body>
				<b-row>
					<b-col class="d-flex justify-content-end">
						<b-button
							class="d-inline text-nowrap"
							variant="primary"
							@click="creationModal.show = !creationModal.show"
						>
							Agregar motivo
						</b-button>
					</b-col>
				</b-row>
			</b-card>
			<b-card no-body>
				<b-table-simple sticky-header="60vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Nombre</b-th>
							<b-th>Activo</b-th>
							<b-th>Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="reason in reasons" :key="reason.id">
							<b-td>{{ reason.name }}</b-td>
							<b-td>
								<b-badge pill :variant="'light-' + (reason.is_active === 1 ? 'success' : 'danger')">
									{{ reason.is_active === 1 ? "SÍ" : "NO" }}
								</b-badge>
								<b-form-checkbox
									v-model="reason.active"
									:true-value="1"
									:false-value="0"
									name="check-button"
									switch
									class="mt-1 text-center"
									@change="updateStatus(reason)"
									style="margin-left: 0.5rem"
								/>
							</b-td>
							<b-td>
								<div class="d-flex justify-content-center" style="gap: 1rem">
									<feather-icon
										size="20"
										icon="EditIcon"
										class="text-primary cursor-pointer"
										@click="openEdit(reason)"
									/>

									<feather-icon
										size="20"
										icon="TrashIcon"
										class="text-danger cursor-pointer"
										@click="remove(reason)"
									/>
								</div>
							</b-td>
						</b-tr>
						<b-tr v-if="reasons.length == 0">
							<b-td colspan="4">
								<p class="mb-0">No hay registros por mostrar</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-card>
		</b-overlay>
		<b-modal
			centered
			v-model="creationModal.show"
			:title="`${creationModal.editing ? 'Actualizando' : 'Creando nuevo'} motivo`"
			@hidden="handleHidden"
		>
			<b-overlay :show="creationModal.loading">
				<ValidationObserver ref="form">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<b-form-group label="Nombre">
							<b-form-input
								:class="{ 'border-danger': errors[0] }"
								placeholder="Ingrese el nombre"
								v-model="creationModal.name"
							/>
						</b-form-group>
					</ValidationProvider>
				</ValidationObserver>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="info" @click="edit" v-if="creationModal.editing">Actualizar</b-button>
				<b-button variant="success" @click="create" v-else>Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import unbornReasonsService from "@/views/brain/encaste/views/reasons/services/unbornReasons.service.js"

export default {
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		reasons: [],
		creationModal: {
			show: false,
			loading: false,
			name: null,
			oldName: null,
			id: null,
			editing: false,
		},
	}),
	methods: {
		handleHidden() {
			this.creationModal = { show: false, loading: false }
			this.$emit("actionDone")
		},
		clearCreation() {
			this.creationModal = {
				show: false,
				loading: false,
				name: null,
				oldName: null,
				id: null,
				editing: false,
			}
		},

		async updateStatus(reason) {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `¿Estás seguro de que quieres ${reason.active ? "activar" : "desactivar"} el estado del motivo ${
					reason.name
				}?`,
				text: "",
			})
			if (!isConfirmed) {
				reason.active = !reason.active
				return
			}
			this.isPreloading()
			try {
				const { data } = await unbornReasonsService.updateStatus(reason.id)
				this.getReasons()
				this.showSuccessToast(data.message)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async getReasons() {
			this.isPreloading()
			try {
				const { data } = await unbornReasonsService.getReasons()
				this.reasons = data.data.map((reason) => ({
					...reason,
					active: reason.is_active === 1 ? true : false,
				}))
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async create() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se creara un nuevo motivo: ${this.creationModal.name}`,
			})
			if (!isConfirmed) return

			this.isPreloading()
			try {
				const res = await unbornReasonsService.storeReason({
					name: this.creationModal.name,
				})

				this.clearCreation()
				this.getReasons()
				this.showSuccessToast(res.message)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		openEdit(reason) {
			this.creationModal = {
				show: true,
				loading: false,
				name: reason.name,
				oldName: reason.name,
				id: reason.id,
				editing: true,
			}
		},
		async edit() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se actualizara el motivo: ${this.creationModal.oldName}`,
			})
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const res = await unbornReasonsService.editReason({
					id: this.creationModal.id,
					name: this.creationModal.name,
				})
				this.clearCreation()
				this.getReasons()
				this.showSuccessToast(res.data.message)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async remove(row) {
			const { isConfirmed } = await this.showConfirmSwal({ text: `Se eliminara el motivo ${row.name}` })
			if (!isConfirmed) return

			this.isPreloading()

			try {
				const res = await unbornReasonsService.deleteReason({ id: row.id })

				this.getReasons()
				this.showSuccessToast("El motivo fue eliminado correctamente.")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	created() {
		this.getReasons()
	},
	watch: {
		action(val) {
			if (val == "create-quality") {
				this.creationModal.active = !this.creationModal.active
			}
		},
	},
}
</script>
