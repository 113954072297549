import axios from "@/axios"

const url = "/api/brain/encaste/unborn/reasons/"
const url2 = "/api/lote/stages/fourth-stage/"

class TopesReasonsService {
	async getReasons() {
		try {
			const data = await axios.get(`${url}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async storeReason({ name }) {
		try {
			const { data } = await axios.post(`${url}`, {
				name,
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async editReason({ id, name }) {
		try {
			const data = await axios.put(`${url}${id}`, { name })
			return data
		} catch (error) {
			throw error
		}
	}

	async deleteReason({ id }) {
		try {
			const data = await axios.delete(`${url}${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async updateStatus(id) {
		try {
			const data = await axios.put(`${url}active/${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async getUnbornReasons(egg_lote_id) {
		try {
			const data = await axios.get(`${url2}get-unborn-reasons/${egg_lote_id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async storeUnbornReasons({ reasons, egg_lote_id }) {
		try {
			const { data } = await axios.post(`${url2}store-unborn-reasons`, {
				reasons,
				egg_lote_id,
			})
			return data
		} catch (error) {
			return error.response
		}
	}
}

export default new TopesReasonsService()
