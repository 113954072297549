<template>
	<b-modal
		ref="dead-pata-by-reason-modal"
		:title="`Cintillo en pata | ${selectedMonth.month_name} | ${selectedReason.reason}: ${selectedReason.qty}`"
		no-close-on-backdrop
		centered
		@hidden="handleHidden"
		size="lg"
		hide-footer
	>
		<div>
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} posturas
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
					<label>Mostrar</label>
					<v-select
						v-model="pagination.perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
						style="width: 90px"
					/>
					<label class="mr-2">posturas</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
				</b-col>
			</b-row>
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header" style="white-space: nowrap">
					<b-th class="text-center">Postura</b-th>
					<b-th class="text-center">Padrillo</b-th>
					<b-th class="text-center">Madrilla</b-th>
					<b-th class="text-center">Cantidad</b-th>
				</b-thead>
				<b-tbody>
					<template v-if="specimens.length > 0">
						<b-tr v-for="(specimen, index) in specimens" :key="index">
							<b-td class="text-center">
								<span>{{ specimen.code }}</span>
							</b-td>

							<b-td class="text-center">
								<SpecimenPlate :specimen="specimen.padrillo" />
							</b-td>
							<b-td class="text-center">
								<SpecimenPlate :specimen="specimen.madrilla" />
							</b-td>

							<b-td class="text-center">
								<span>{{ specimen.qty }}</span>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="specimens.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay posturas para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</b-modal>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import specimensService from "@/services/specimens.service"
export default {
	components: { SpecimenPlate },
	props: {
		start_date: String,
		end_date: String,
		selectedReason: Object,
		selectedMonth: Object,
	},
	data() {
		return {
			isLoading: false,
			specimens: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
		}
	},
	async created() {
		await this.getDetails()
	},
	methods: {
		async refresh() {
			await this.getDetails()
		},
		handleHidden() {
			this.isLoading = false
			this.$emit("closing")
		},
		async getDetails() {
			this.isPreloading()
			try {
				const { data } = await specimensService.getDeadPataByReasonDetails({
					per_page: this.pagination.perPage,
					page: this.pagination.curPage,
					start_date: this.start_date,
					end_date: this.end_date,
					reason_id: this.selectedReason.reason_id,
					month: Number(this.selectedMonth.month),
				})
				this.specimens = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getDetails()
		},
		"pagination.perPage": async function () {
			await this.getDetails()
		},
	},
	async mounted() {
		this.$refs["dead-pata-by-reason-modal"].toggle()
	},
}
</script>

<style>
.label-filter {
	font-size: 0.8rem;
}

.nav.nav-tabs {
	flex-wrap: nowrap;
	overflow-x: auto;
	padding-bottom: 0.5rem;
}

.nav-link {
	white-space: nowrap;
}
</style>
