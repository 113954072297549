<template>
	<div class="card mb-0 container-card-dashboard">
		<div class="header p-2">
			<div class="d-flex align-items-center">
				<h1>Ranking de Padrillos por % de Machos</h1>
			</div>
		</div>
		<div class="body p-2">
			<div class="container-select-tabs-dashboard">
				<div class="">
					<div
						class="container-select-tabs-dashboard-item"
						:class="[category == 1 ? 'active' : '']"
						@click="setCategory(1)"
					>
						<p class="mb-0">Top de 10 Padrillos</p>
					</div>
					<div
						class="container-select-tabs-dashboard-item"
						:class="[category == 2 ? 'active' : '']"
						@click="setCategory(2)"
					>
						<p class="mb-0">Listado Total</p>
					</div>
				</div>
			</div>

			<div class="mt-1">
				<div v-if="category === 1" :key="keyChart">
					<apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
				</div>
				<div v-else>
					<b-row class="mb-1">
						<b-col class="d-flex align-items-center">
							<small class="text-secondary">
								Mostrando {{ pagination.from }} a {{ pagination.to }} de
								{{ pagination.total }} registros
							</small>
						</b-col>
						<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
							<b-pagination
								v-model="pagination.curPage"
								:total-rows="pagination.total"
								:per-page="pagination.perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
					<b-row class="mb-1">
						<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start">
							<label>Mostrar</label>
							<v-select
								v-model="pagination.perPage"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
								style="width: 90px"
							/>
							<label class="mr-2">registros</label>
							<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
						</b-col>
						<b-col cols="12" xl="5">
							<div class="container-btns-page-specimens">
								<div class="d-flex">
									<b-form-input
										v-model="search"
										class="d-inline-block mr-1"
										placeholder="Placa o alias..."
										@keyup.enter="getChicks"
										debounce="500"
									/>
								</div>
							</div>
						</b-col>
					</b-row>
					<b-table-simple responsive sticky-header="30vh" no-border-collapse>
						<b-thead class="sticky-header" style="white-space: nowrap">
							<b-th class="text-center" style="vertical-align: middle">Padrillo</b-th>
							<b-th class="text-center" style="vertical-align: middle">% Machos</b-th>
							<b-th class="text-center" style="vertical-align: middle">Total Crías</b-th>
							<b-th class="text-center" style="vertical-align: middle">Machos</b-th>
							<b-th class="text-center" style="vertical-align: middle">Hembras</b-th>
							<b-th class="text-center" style="vertical-align: middle">
								Sin
								<br />
								asignar
							</b-th>
						</b-thead>
						<b-tbody>
							<template v-if="chicks.length > 0" v-for="(chick, index) in dataWithTotals">
								<b-tr :key="index">
									<b-td class="text-center">
										<span class="cursor-pointer" style="font-weight: bold" @click="linkSpecimen(chick.padrillo.id)">{{ chick.padrillo.alias }}</span>
									</b-td>
									<b-td class="text-center">
										<span>{{ chick.machos_percentage_format }}%</span>
									</b-td>
									<b-td class="text-center">
										<span>{{ chick.total_qty }}</span>
									</b-td>
									<b-td class="text-center">
										<span>{{ chick.machos_qty }}</span>
									</b-td>
									<b-td class="text-center">
										<span>{{ chick.hembras_qty }}</span>
									</b-td>
									<b-td class="text-center">
										<span>{{ chick.sin_asignar_qty }}</span>
									</b-td>
								</b-tr>
							</template>
							<b-tr v-if="chicks.length === 0">
								<b-td colspan="10" class="text-center" style="height: 80px">
									No hay registros para mostrar
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import encasteDashboardService from "@/services/encaste/encasteReports.service"

export default {
	name: "DashboardUnborn",
	components: {
		apexchart: VueApexCharts,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			category: 1,

			series: [
				{
					name: "Machos",
					data: [],
				},
				{
					name: "Hembras",
					data: [],
				},
				{
					name: "Sin Asignar",
					data: [],
				},
			],

			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					toolbar: { show: false },
				},
				plotOptions: {
					bar: { dataLabels: { position: "top" }, borderRadius: 6, borderRadiusApplication: "end" },
				},
				colors: ["#4f71ff", "#f941ff", "#b5b5b5"],
				dataLabels: {
					enabled: true,
					formatter: (v) => v + "%",
					offsetY: -20,
					style: { fontSize: "10px", colors: ["#000"] },
				},
				legend: {
					showForSingleSeries: true,
					position: "bottom",
					horizontalAlign: "right",
					fontSize: "16px",
					fontWeight: 600,
					labels: { colors: "#82868b" },
					markers: { width: 20, height: 16, radius: 4 },
					itemMargin: { horizontal: 10 },
				},
				stroke: { show: true, width: 2, colors: ["transparent"] },
				xaxis: {
					labels: { style: { colors: "#9aa0ac", fontSize: "14px" } },
					categories: [],
				},
				yaxis: {
					title: { text: "Ranking de Padrillos por % de Machos", style: { color: "#9aa0ac" } },
					labels: { style: { colors: ["#9aa0ac"] } },
				},
				fill: { opacity: 1 },
				tooltip: { y: { formatter: (v) => v + "%" } },
			},

			chicks: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
			search: null,
			keyChart: 0,
		}
	},
	async mounted() {
		await this.initChart()
		await this.getChicks()
	},
	computed: {
		dataWithTotals() {
			return this.chicks.map((item) => {
				const total =
					Number(item.hembras_qty || 0) + Number(item.machos_qty || 0) + Number(item.sin_asignar_qty || 0)

				return {
					...item,
					total_qty: total,
				}
			})
		},
	},
	methods: {
		linkSpecimen(id){
			window.open(`/ejemplares/detalle/${id}`, "_blank")
		},
		async setCategory(category) {
			this.category = category
		},
		async refresh() {
			await this.getChicks()
		},
		async initChart() {
			const res = await encasteDashboardService.getTopTenPadrillos({
				start_date: this.start_date,
				end_date: this.end_date,
			})

			this.chartOptions.xaxis.categories = res.data.map(
				(item) =>
					`${item.padrillo.plate} ${item.padrillo.alias ? "/" : ""} ${
						item.padrillo.alias ? item.padrillo.alias : ""
					}`
			)

			const maleData = res.data.map((item) => parseInt(item.machos_percentage_format, 10))
			const femaleData = res.data.map((item) => parseInt(item.hembras_percentage_format, 10))
			const unassignedData = res.data.map((item) => parseInt(item.sin_asignar_percentage_format, 10))

			this.series[0].data = maleData
			this.series[1].data = femaleData
			this.series[2].data = unassignedData

			this.keyChart = this.keyChart + 1
		},

		async getChicks() {
			const res = await encasteDashboardService.getChicksQuantityByPadrillo({
				start_date: this.start_date,
				end_date: this.end_date,
				per_page: this.pagination.perPage,
				page: this.pagination.curPage,
				text: this.search,
			})
			this.chicks = res.data.data
			this.pagination.total = res.data.total
			this.pagination.from = res.data.from
			this.pagination.to = res.data.to
		},
	},
	watch: {
		async start_date() {
			await this.initChart()
			await this.getChicks()
		},
		"pagination.curPage": async function () {
			await this.getChicks()
		},
		"pagination.perPage": async function () {
			await this.getChicks()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
}
.container-select-tabs-dashboard {
	// background: red;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	// background: red !important;
	// margin: 0.5rem 0;
	margin-top: 0.5rem;
	@media screen and (min-width: 900px) {
		flex-direction: row !important;
	}
	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;
		:last-child {
			margin-right: 0;
		}
		p {
			text-align: center;
			font-size: 14px;
			padding: 0.4rem 0.5rem;
			border-radius: 4px;
			// width: 100px;
			font-weight: bolder;
			cursor: pointer;
			@media screen and (min-width: 600px) {
				padding: 0.4rem 1rem;
			}
			// border-bottom: 2px solid transparent;
			@media screen and (min-width: 900px) {
				// margin: 0 5rem;
				padding: 0.4rem 2rem;
			}
		}
	}
	.active {
		p {
			transition: 0.3s all ease-in-out;
			font-weight: bold;
			color: #7367f0;
			background: rgba(92, 84, 255, 0.4);
			// border-bottom: 2px solid #7367f0;
		}
	}
	:first-child {
		display: flex;
	}
}
</style>
