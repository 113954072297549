<template>
	<b-overlay class="mb-2 card p-2 h-full" :show="isLoading">
		<span class="title align-self-start">TRIUNFOS POR EDAD</span>
		<div class="d-flex justify-content-start mb-3 title">Total de Triunfos: {{ total }}</div>
		<div class="d-flex flex-column w-100">
			<b-table-simple responsive sticky-header style="height: auto" no-border-collapse>
				<b-thead class="sticky-header" style="white-space: nowrap">
					<b-th class="text-center px-0" style="vertical-align: middle">
						<span>Rango de Edad</span>
					</b-th>
					<b-th class="text-center px-0" style="vertical-align: middle">
						<span>Triunfos</span>
					</b-th>
					<b-th class="text-center px-0" style="vertical-align: middle">
						<span>Porcentaje</span>
					</b-th>
				</b-thead>
				<b-tbody>
					<template v-if="ranges.length > 0">
						<b-tr v-for="(range, index) in ranges" :key="index">
							<b-td class="text-center px-0">
								{{ range.description }}
							</b-td>
							<b-td class="text-center text-success px-0">
								<span
									@click="range.wins != 0 ? openDetailModal(range) : null"
									:class="{ 'underline-hover': range.wins != 0 }"
								>
									{{ range.wins }}
								</span>
							</b-td>
							<b-td class="text-center px-0">
								<span>{{ range.percentage }}%</span>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="ranges.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
		<WinsDetailModal v-if="showModal" :range="rangeSelected" :season="season" @closing="showModal = false" />
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import WinsDetailModal from "@/views/amg/tournaments/v2/components/wins-by-age-ranges/components/WinsDetailsModal.vue"
export default {
	components: { WinsDetailModal },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		ranges: [],
		pagination: {
			total: 0,
			from: 0,
			to: 0,
			curPage: 1,
			perPage: 25,
		},
		rangeSelected: null,
		perPageOptions: [10, 25, 50],
		search: null,
		showModal: false,
		total: 0,
	}),
	computed: {},
	methods: {
		openDetailModal(range) {
			this.rangeSelected = {
				sup: range.sup,
				inf: range.inf,
				description: range.description,
			}
			this.showModal = true
		},
		async getWinsByAgeRange() {
			const params = {
				start_date: this.season.start,
				end_date: this.season.end,
			}
			try {
				this.isLoading = true
				const { data } = await tournamentService.getWinsByAgeRange(params)
				this.ranges = data.ranges
				this.total = data.total
			} catch (error) {
				console.error(error)
			} finally {
				this.isLoading = false
			}
		},
	},
	async mounted() {
		await this.getWinsByAgeRange()
	},
	watch: {
		"season.start"() {
			this.getWinsByAgeRange()
		},
		"pagination.curPage": async function () {
			await this.getWinsByAgeRange()
		},
	},
}
</script>

<style scoped lang="scss">
.title {
	display: block;
	margin-bottom: 2rem;
	color: var(--primary);
	font-size: 1.5rem;
	font-weight: 700;
}

.chadow {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 9rem;
	aspect-ratio: 1/1;
	background: transparent;
	box-shadow: 0 0 0.5rem #000;
	border-radius: 50%;
	transform: translate(-50%, -64%);
}

@media screen and (min-width: 768px) {
	.chadow {
		width: 12rem;
		transform: translate(-50%, -62%);
	}
}

@media screen and (min-width: 1024px) {
	.chadow {
		width: 40%;
		transform: translate(-50%, -98%);
	}
}

@media screen and (min-width: 1440px) {
	.chadow {
		width: 40%;
		transform: translate(-50%, -62%);
	}
}
.underline-hover {
	text-decoration: underline;
	transition: all 0.1s ease;
}

.underline-hover:hover {
	font-weight: bold;
	cursor: pointer;
	font-size: 1.2rem;
}
.container-text {
	background: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 30%;
	width: 30%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	box-shadow: 0 0 21px rgba(0, 0, 0, 0.17);
	@media screen and (min-width: 420px) {
		height: 40%;
		width: 40%;
	}
	@media screen and (min-width: 1024px) {
		height: 40%;
		width: 40%;
	}
	.number {
		font-size: 30px;
		color: #434343;
		font-weight: bolder;
		@media screen and (min-width: 420px) {
			font-size: 40px;
		}
	}
	.text {
		margin-bottom: 0;
		font-size: 16px;
		color: #909090;
		@media screen and (min-width: 420px) {
			font-size: 20px;
		}
	}
}

.container-legend {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0;
	@media screen and (min-width: 1024px) {
		padding: 0 1rem;
	}
	@media screen and (min-width: 1800px) {
		padding: 0 2rem;
	}
	.item-legend {
		display: flex;
		flex-direction: column;
		align-items: center;
		.title {
			color: #909090;
			font-size: 16px;
			font-weight: 500;
			font-size: 12px;
			@media screen and (min-width: 1024px) {
				font-size: 14px;
			}
			@media screen and (min-width: 1800px) {
				font-size: 18px;
			}
		}
		.number {
			color: #434343;
			font-weight: bolder;
			font-size: 12px;
			@media screen and (min-width: 1024px) {
				font-size: 14px;
			}
			@media screen and (min-width: 1800px) {
				font-size: 17px;
			}
		}
	}
}

.container-bars {
	overflow: auto;
	.bars {
		min-width: 1000px;
	}
}
</style>

<style>
.myDonut .apexcharts-legend-series {
	flex-direction: column-reverse;
}

.apexcharts-toolbar {
	background: red !important;
	display: none !important;
}
</style>
