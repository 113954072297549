<template>
	<div>
		<div class="d-flex">
			<div class="dseCard__head dseCard__head--wfull">Efectividad de hijos en torneos</div>
		</div>
		<b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
			<div class="dseCard__body dseCard__body--wfull position-relative pt-2">
				<VueApexCharts
					type="donut"
					style="margin-top: 24px"
					:height="300"
					:options="donutOptsEFA"
					:series="donutDataEFA"
				/>
				<div
					class="position-absolute d-flex align-items-center justify-content-between"
					style="top: 1rem; left: 1rem; width: 90%"
				>
					<b-form-select
						class="cursor-pointer text-primary"
						size="sm"
						style="width: 150px"
						v-model="seasonSelected"
						:options="seasonOptions"
						@input="changeSeason"
					></b-form-select>
					<div class="d-flex flex-column justify-content-center" style="font-size: 1rem">
						Efectividad: {{ effectivity ? effectivity : 0 }}%
					</div>
				</div>
				<small class="font-small-1 d-flex align-items-center">
					<FeatherIcon icon="InfoIcon" size="14" />
					<span class="ml-25 mt-05">CLICK EN LOS RESULTADOS PARA VER MAS DETALLES</span>
				</small>
			</div>
		</b-overlay>
	</div>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import ConfrontationsResultService from "@/services/confrontationsResults.service"

export default {
	name: "ChildsEffectivenessChart",
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		VueApexCharts,
	},
	data() {
		return {
			donutDataEFA: [],
			donutOptsEFA: {},
			seasonsTournaments: [],
			seasonOptions: [{ value: 0, text: `Todos los años` }],
			seasonSelected: 0,
			currentSeason: null,
			resultsSeason: [],
			positionLegend: null,
			effectivity: null,
			showLoading: false,
		}
	},
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	mounted() {
		this.positionLegend = this.widthScreen < 1600 ? "bottom" : "right"
		if (this.specimen.category == 1 || this.specimen.category == 2) {
			this.getSeasons()
		}
	},
	methods: {
		async getSeasons() {
			const { data } = await ConfrontationsResultService.getSeasons({ status: null })
			this.seasonsTournaments = data
			data.forEach((index) => {
				this.seasonOptions.push({ value: `${index.id}`, text: `${index.season}` })
			})
			this.isPreloading()
			this.configEra()
			this.isPreloading(false)
		},
		async changeSeason() {
			if (this.seasonSelected != 0) {
				this.currentSeason = this.seasonsTournaments.find((index) => this.seasonSelected == index.id)
			}
			this.configEra()
		},
		async configEra() {
			this.showLoading = true

			if (this.seasonSelected != 0) {
				const { data } = await ConfrontationsResultService.getSeasonConfrontations(
					this.specimen.category,
					this.specimen.id,
					this.currentSeason.start,
					this.currentSeason.end
				)
				this.loadEraPerSeason(data)
				this.showLoading = false
			} else {
				const { data } = await ConfrontationsResultService.getSeasonConfrontations(
					this.specimen.category,
					this.specimen.id
				)
				this.loadEraPerSeason(data)
				this.showLoading = false
			}
		},
		async loadEraPerSeason(data) {
			const dataConfrontations = []
			const labelsConfrontations = []
			let points = data.find((it) => it.id == 1).counter * 2 + data.find((it) => it.id == 2).counter
			let average = data.reduce((accumulator, currentValue) => accumulator + currentValue.counter, 0) * 2
			let acumulated = data.reduce((accumulator, currentValue) => accumulator + currentValue.counter, 0)
			this.effectivity = (points / average) * 100

			data.forEach((itemm, index) => {
				const valCounter = acumulated == 0 && index == 2 ? 100 : itemm.counter
				dataConfrontations.push(valCounter)
				labelsConfrontations.push(`${itemm.name.toUpperCase()} (${itemm.counter ?? 0})`)
			})
			if (!this.effectivity) {
				// this.donutDataEFA = [1]
				this.resultsSeason = data
				this.donutDataEFA = dataConfrontations
				this.donutOptsEFA = {
					// labels: ["Sin resultados"],
					labels: labelsConfrontations,
					colors: ["rgb(153, 153, 153)"],
					// colors: ["rgb(153, 153, 153)"],
					legend: {
						position: this.$store.state.app.windowWidth > 990 ? "bottom" : "right",
						fontWeight: 600,
						labels: { colors: "rgb(153, 153, 153)" },
					},
					dataLabels: {
						formatter: (val, opts) => {
							if (Number.isInteger(val)) return `${val.toFixed(2)}%`
							return `${val.toFixed(2)}%`
						},
					},
					tooltip: {
						enabled: acumulated > 0,
					},
					dataLabels: {
						// valida si hay linea desconocida en el primer index del array de lineas para saber si mostrar etiquetas
						formatter: (val, opts) => {
							return ""
						},
					},
					states: {
						active: {
							allowMultipleDataPointsSelection: true,
						},
					},
					chart: {
						events: {
							dataPointSelection: this.openConfrontationsModal,
						},
					},
				}
				return
			}

			this.resultsSeason = data
			this.donutDataEFA = dataConfrontations
			this.donutOptsEFA = {
				labels: labelsConfrontations,
				colors: ["#b9b3f8", "#9d95f5", "#7367f0", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
				legend: { position: this.$store.state.app.windowWidth > 990 ? "bottom" : "right" },
				dataLabels: {
					formatter: (val, opts) => {
						if (Number.isInteger(val)) return `${val.toFixed(2)}%`
						return `${val.toFixed(2)}%`
					},
				},
				tooltip: {
					enabled: true,
					y: {
						formatter: (val, opts) => {
							return `${val}`
						},
						title: {
							formatter: (val, opts) => {
								return `${val.slice(0, -4)}:`
							},
						},
					},
				},
				states: {
					active: {
						allowMultipleDataPointsSelection: true,
					},
				},
				chart: {
					events: {
						dataPointSelection: this.openConfrontationsModal,
					},
				},
			}
		},
		openConfrontationsModal(e, result_id, result_alter) {
			let result = (result_alter.dataPointIndex ?? result_id) + 1
			let counterResults = this.resultsSeason.find((index) => index.id == result)
			if (counterResults.counter > 0) {
				let dataSelected = this.seasonsTournaments.find((index) => index.id == Number(this.seasonSelected))
				let infoSeason = {
					counterResults,
					dataSelected,
				}
				console.log(infoSeason.counterResults)
				console.log(dataSelected)
				this.$emit("modal-actions", "show-efectiveness-for-season", infoSeason)
			} else {
				this.showToast(
					"info",
					"top-right",
					"Dashboard",
					"SuccessIcon",
					`No hay Resultados para ${counterResults.name}`
				)
			}
		},
	},
}
</script>
