<template>
	<div>
		<b-modal
			ref="corral-selector-modal"
			@hidden="$emit('hidden')"
			title="Elegir corral"
			no-close-on-backdrop
			cancel-title="Cancelar"
			size="md"
		>
			<ValidationObserver ref="form">
				<b-row >
					<b-col class="input-select-corral" cols="12">
						<b-form-group id="input-group-1" label="Corrales activos:" label-for="input-1">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="corral_id"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="corrals"
									:clearable="false"
									label="full_name"
									:reduce="(option) => option.id"
									:class="{ 'border-danger rounded': errors[0] }"
								>
									<template v-slot:option="option">
										<div class="d-flex justify-content-between align-items-center">
											<span>{{ option.full_name }}</span>
										</div>
									</template>
								</v-select>
								<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
					</b-col>
				</b-row>
			</ValidationObserver>
			<template #modal-footer>
				<b-button @click="$emit('hidden')" v-if="!loading" variant="secondary"> Cancelar </b-button>
				<b-button variant="secondary" disabled v-else>
					<b-spinner small />
					Cargando...
				</b-button>
				<b-button variant="primary" @click="save" v-if="!loading"> Guardar </b-button>
				<b-button variant="primary" disabled v-else>
					<b-spinner small />
					Cargando...
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import corralService from "@/services/encaste/corral.service";

export default {
	name: "CorralSelectorModal",
	components: {
	},
	mixins: [modalMixin],
	props: {
	},
	async mounted() {
		this.isPreloading();
		await this.getActiveCorrals();
		this.toggleModal("corral-selector-modal");
		this.isPreloading(false);
	},
	computed: {},
	data() {
		return {
			corral_id: null,
			loading: false,
			corrals: [],
		};
	},
	methods: {
		async getActiveCorrals() {
			try {
				const { data } = await corralService.getActiveCorrals();
				this.corrals = data.map((corral) => ({
					...corral,
					full_name: `${corral.code} - ${corral.alias}`,
				}));
			} catch (error) {
				console.log(error)
			}
		},
		async save(){
			if (!(await this.$refs.form.validate())) {
				this.showToast("danger", "top-right", "Error", "AlertCircleIcon", "Complete los campos");
				return;
			}
			this.loading = true;
			this.$emit("confirmSixthStage", this.corral_id)
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
