<template>
	<div class="card mb-0 container-card-dashboard">
		<div class="header p-2">
			<div class="d-flex align-items-center">
				<h1>Pollos Nacidos por Sexo</h1>
			</div>
		</div>
		<div class="body p-2">
			<div class="container-select-tabs-dashboard">
				<div class="">
					<div
						class="container-select-tabs-dashboard-item"
						:class="[category == 1 ? 'active' : '']"
						@click="setCategory(1)"
					>
						<p class="mb-0">Anual</p>
					</div>
					<div
						class="container-select-tabs-dashboard-item"
						:class="[category == 2 ? 'active' : '']"
						@click="setCategory(2)"
					>
						<p class="mb-0">Mensual</p>
					</div>
				</div>
			</div>

			<div class="mt-1">
				<div v-if="category === 1" class="pie-container" :key="keyPie">
					<apexchart type="pie" width="400" :options="chartOptions1" :series="series1"></apexchart>
					<div>
						<div v-for="data in totalData" class="item-count">
							<p class="mb-0">
								{{ data.gender }}{{ data.gender == "SIN ASIGNAR" ? "" : "S" }} {{ data.gender_counter }}
							</p>
						</div>
					</div>
				</div>
				<div v-else :key="keyBars">
					<apexchart
						type="bar"
						height="350"
						:options="chartOptions2"
						:series="series2"
						@click="openDetailMonthly"
					></apexchart>
				</div>
			</div>
		</div>

		<DetailChickBySex
			v-if="showDetail.show"
			:start_date="start_date"
			:end_date="end_date"
			:info="showDetail.info"
			@closing="showDetail.show = false"
		/>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import encasteDashboardService from "@/services/encaste/encasteReports.service"
import moment from "moment"
import DetailChickBySex from "@/views/amg/encaste/dashboard-encaste/modal/DetailChickBySex.vue"

export default {
	name: "DashboardChicksBornBySex",
	components: {
		apexchart: VueApexCharts,
		DetailChickBySex,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			category: 1,

			series1: [],

			series2: [
				{
					name: "Machos",
					data: [],
				},
				{
					name: "Hembras",
					data: [],
				},
				{
					name: "Sin Asignar",
					data: [],
				},
			],

			chartOptions1: {
				chart: {
					type: "pie",

					events: {
						dataPointSelection: (event, chartContext, config) => {
							const clickedIndex = config.dataPointIndex // Índice del segmento clickeado
							const value = this.series1[clickedIndex] // Valor correspondiente

							this.showDetail.info = {
								type: "annual",
								gender: this.genderOptions[clickedIndex],
								total: value,
							}
							this.showDetail.show = true
						},
					},
				},
				colors: ["#4f71ff", "#f941ff", "#b5b5b5"],
				labels: ["MACHOS", "HEMBRAS", "SIN ASIGNAR"],
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],

				fill: {
					type: "gradient",
				},
				legend: {
					show: false,
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"],
						opacity: 0.5,
					},
				},
			},

			chartOptions2: {
				chart: {
					type: "bar",
					height: 350,
					toolbar: { show: false },
				},
				plotOptions: {
					bar: { dataLabels: { position: "top" }, borderRadius: 6, borderRadiusApplication: "end" },
				},
				colors: ["#4f71ff", "#f941ff", "#b5b5b5"],
				dataLabels: {
					enabled: true,
					formatter: (v) => v,
					offsetY: -20,
					style: { fontSize: "10px", colors: ["#000"] },
				},
				legend: {
					showForSingleSeries: true,
					position: "bottom",
					horizontalAlign: "right",
					fontSize: "16px",
					fontWeight: 600,
					labels: { colors: "#82868b" },
					markers: { width: 20, height: 16, radius: 4 },
					itemMargin: { horizontal: 10 },
				},
				stroke: { show: true, width: 2, colors: ["transparent"] },
				xaxis: {
					labels: { style: { colors: "#9aa0ac", fontSize: "14px" } },
					categories: [],
				},
				yaxis: {
					title: { text: "Pollos Nacidos por Sexo", style: { color: "#9aa0ac" } },
					labels: { style: { colors: ["#9aa0ac"] } },
				},
				fill: { opacity: 1 },
				tooltip: { y: { formatter: (v) => v } },
			},

			totalData: [],
			monthlyData: [],
			showDetail: {
				info: null,
				show: false,
			},
			keyPie: 0,
			keyBars: 0,
			genderOptions: ["MACHO", "HEMBRA", "SIN ASIGNAR"],
		}
	},
	async mounted() {
		await this.initChart()
	},
	computed: {},
	methods: {
		openDetailMonthly(event, chartContext, config) {
			if (event.target.classList.contains("apexcharts-bar-area")) {
				const { seriesIndex, dataPointIndex } = config
				const chickSex = this.genderOptions[seriesIndex]

				const yearMonth = this.monthlyData[dataPointIndex]
				const genderVar = {
					["MACHO"]: "macho",
					["HEMBRA"]: "hembra",
					["SIN ASIGNAR"]: "sin_asignar",
				}
				this.showDetail.info = {
					type: "monthly",
					year_month: yearMonth.lote_start_date,
					total: yearMonth[genderVar[chickSex]],
					gender: chickSex,
				}
				this.showDetail.show = true
			}
		},
		async setCategory(category) {
			this.category = category
		},
		async initChart() {
			const res = await encasteDashboardService.getChicksBySex({
				start_date: this.start_date,
				end_date: this.end_date,
			})

			this.monthlyData = res.data.monthly

			const xCategories = [
				...new Set(
					res.data.monthly.map((item) =>
						moment(`${item.lote_start_date}-01`).locale("es").format("MMMM").toLocaleUpperCase().slice(0, 3)
					)
				),
			]

			const machosData1 = res.data.annual.find((item) => item.gender === "MACHO")
			const hembrasData1 = res.data.annual.find((item) => item.gender === "HEMBRA")
			const sinAsignarData1 = res.data.annual.find((item) => item.gender === "SIN ASIGNAR")
			this.series1 = [machosData1.gender_counter, hembrasData1.gender_counter, sinAsignarData1.gender_counter]

			this.totalData = [machosData1, hembrasData1, sinAsignarData1]

			const machosData2 = res.data.monthly.map((item) => parseInt(item.macho, 10))
			const hembrasData2 = res.data.monthly.map((item) => parseInt(item.hembra, 10))
			const sinAsignarData2 = res.data.monthly.map((item) => parseInt(item.sin_asignar, 10))

			this.chartOptions2.xaxis.categories = xCategories
			this.series2[0].data = machosData2
			this.series2[1].data = hembrasData2
			this.series2[2].data = sinAsignarData2

			this.keyPie = this.keyPie + 1
			this.keyBars = this.keyBars + 1
		},
	},
	watch: {
		async start_date() {
			await this.initChart()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
}

.container-select-tabs-dashboard {
	// background: red;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	// background: red !important;
	// margin: 0.5rem 0;
	margin-top: 0.5rem;
	@media screen and (min-width: 900px) {
		flex-direction: row !important;
	}
	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;
		:last-child {
			margin-right: 0;
		}
		p {
			text-align: center;
			font-size: 14px;
			padding: 0.4rem 0.5rem;
			border-radius: 4px;
			// width: 100px;
			font-weight: bolder;
			cursor: pointer;
			@media screen and (min-width: 600px) {
				padding: 0.4rem 1rem;
			}
			// border-bottom: 2px solid transparent;
			@media screen and (min-width: 900px) {
				// margin: 0 5rem;
				padding: 0.4rem 2rem;
			}
		}
	}
	.active {
		p {
			transition: 0.3s all ease-in-out;
			font-weight: bold;
			color: #7367f0;
			background: rgba(92, 84, 255, 0.4);
			// border-bottom: 2px solid #7367f0;
		}
	}
	:first-child {
		display: flex;
	}
}

.item-count {
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem 0.4rem;
	border-radius: 4px;
	width: auto;
	margin-right: 0.5rem;

	@media screen and (min-width: 600px) {
		padding: 0.5rem 1rem;
		margin-top: 2rem;
	}
	p {
		font-weight: bold;
		font-size: 12px;
		text-align: center;
	}

	&:first-child {
		background-color: #dae1ff;
		color: #466aff;
	}

	&:nth-child(2) {
		background-color: #fed9ff;
		color: #f941ff;
	}

	&:last-child {
		background-color: #e6e6e6;
		color: #b5b5b5;
	}
}

.pie-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: 1200px) {
		flex-direction: row;
	}
}
</style>
