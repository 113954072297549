<template>
	<div style="background: #f3f2f7; border: 1px solid #cecece; border-radius: 0 8px 0 0" class="pt-50 pt-md-2">
		<div class="p-1 mx-md-2" no-body>
			<DsGeneralCards :season="season" />
			<DsGeneralAndMonthlyResults :season="season" />
			<b-row class="mb-2">
				<b-col cols="12" lg="6">
					<WinsByAgeRanges :season="season" />
				</b-col>
				<b-col cols="12" lg="6">
					<DsResultsByRival :season="season" />
				</b-col>
			</b-row>
			<DsOriginResults :season="season" />
			<DsTournamentEfectivities :season="season" />
			<DsParentEffectivities :season="season" />
			<DsExpenses :season="season" />
		</div>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

import DsGeneralCards from "../components/DsGeneralCards.vue"
import DsGeneralAndMonthlyResults from "../components/DsGeneralAndMonthlyResults.vue"
import DsOriginResults from "../components/DsOriginResults.vue"
import DsTournamentEfectivities from "../components/DsTournamentEfectivities.vue"
import DsParentEffectivities from "../components/DsParentEffectivities.vue"
import DsExpenses from "../components/DsExpenses.vue"
import WinsByAgeRanges from "@/views/amg/tournaments/v2/components/wins-by-age-ranges/WinsByAgeRanges.vue"
import DsResultsByRival from "@/views/amg/tournaments/v2/components/results-by-rival/DsResultsByRival.vue"
export default {
	components: {
		VueApexCharts,
		DsGeneralCards,
		DsGeneralAndMonthlyResults,
		DsOriginResults,
		DsTournamentEfectivities,
		DsParentEffectivities,
		DsExpenses,
		WinsByAgeRanges,
		DsResultsByRival,
	},
	props: { season: Object },
	data: () => ({
		efectivity: [
			{ field: "Triunfos", quantity: 99 },
			{ field: "Empates", quantity: 99 },
			{ field: "Derrotas", quantity: 99 },
			{ field: "Total", quantity: 99 },
		],
		barData: [
			{
				name: "Net Profit",
				data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
			},
			{
				name: "Revenue",
				data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
			},
			{
				name: "Free Cash Flow",
				data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
			},
		],
		barOpts: {
			colors: ["#b9b3f8", "#9d95f5", "#7367f0", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
			legend: { position: "bottom" },
		},
		pieData: [1],
		pieOpts: {
			labels: ["1"],
			colors: ["#b9b3f8", "#9d95f5", "#7367f0", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
			legend: { position: "bottom" },
		},
	}),
	methods: {
		efectivityColor(idx) {
			switch (idx) {
				case 0:
					return "text-success"
				case 1:
					return "text-warning"
				case 2:
					return "text-danger"
				case 3:
					return "text-info"
				default:
					return "text-secondary"
			}
		},
	},
}
</script>
