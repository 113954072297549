<template>
	<div>
		<b-overlay :show="isLoading">
			<b-card no-body>
				<b-table-simple sticky-header="60vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Categoria</b-th>
							<b-th>Cualidades</b-th>
							<b-th>Características</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(counter, idx) in counters" :key="idx">
							<b-td>
								<span style="font-weight: bold; text-transform: uppercase">
									{{ counter.category.description }}
								</span>
							</b-td>
							<b-td>
								<span
									class="cursor-pointer"
									:class="counter.qualities > 0 ? 'text-primary' : 'text-danger'"
									style="text-decoration: underline; font-weight: 600"
									@click="openAttributes(counter.category, 1)"
								>
									{{ counter.qualities }}
								</span>
							</b-td>
							<b-td>
								<span
									class="cursor-pointer"
									:class="counter.characteristics > 0 ? 'text-primary' : 'text-danger'"
									style="text-decoration: underline; font-weight: 600"
									@click="openAttributes(counter.category, 2)"
								>
									{{ counter.characteristics }}
								</span>
							</b-td>
						</b-tr>
						<b-tr v-if="counters.length == 0">
							<b-td colspan="3">
								<p class="mb-0">No hay registros por mostrar</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-card>
		</b-overlay>

		<AttributesDetailModal
			v-if="attributes.show"
			:info="attributes.info"
			@refresh="getCounters"
			@closing="attributes.show = false"
		/>
	</div>
</template>

<script>
import qualitiesService from "@/views/brain/specimens/views/qualities/services/qualities.services.js"
import AttributesDetailModal from "@/views/brain/specimens/views/qualities/AttributesDetailModal.vue"

export default {
	components: { AttributesDetailModal },
	props: {},
	data: () => ({
		isLoading: false,
		qualities: [],
		counters: [],
		attributes: {
			show: false,
			info: null,
		},
	}),
	methods: {
		handleHidden() {
			this.creationModal = { show: false, loading: false }
		},

		openAttributes(category, type) {
			this.attributes.show = true
			this.attributes.info = { category: category, type: type }
		},

		async getCounters() {
			this.isPreloading()
			try {
				const { data } = await qualitiesService.getCounters()

				this.counters = data.data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	async created() {
		await this.getCounters()
	},
}
</script>
