<template>
	<b-modal
		ref="unborn-reasons-modal"
		:title="getTitle"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="md"
		no-close-on-backdrop
	>
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-form-group label-for="reason" v-for="(data, index) in reasonsData" :key="'reason-form-' + index">
					<template #label>
						<span style="font-size: 0.9rem">Seleccionar motivo {{ index + 1 }}</span>
					</template>
					<ValidationProvider v-slot="{ errors }" rules="required">
						<v-select
							:id="'reason-' + index"
							v-model="data.reason"
							:options="reasons"
							label="name"
							:reduce="(option) => option.id"
							:clearable="false"
							:placeholder="'Seleccionar motivo...'"
							:disabled="disabled"
							:class="{ 'border-danger rounded': !!errors[0] }"
						/>
					</ValidationProvider>
				</b-form-group>
			</ValidationObserver>
		</b-overlay>

		<template #modal-footer>
			<b-button variant="success" @click="save" :disabled="disabled">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import unbornReasonsService from "@/views/brain/encaste/views/reasons/services/unbornReasons.service.js"

export default {
	components: {},
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		disabled: Boolean,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			reasons: [],
			existingReasons: [],
			reasonsData: [],
		}
	},
	async created() {
		await this.getReasons()
		if (!this.info.n_nac_id) {
			this.$emit("saveUnBornByPosture", this.info)
		}
	},
	computed: {
		getTitle() {
			return `Motivos de no nacidos |  ${
				this.info.padrillo.alias
					? `${this.info.padrillo.alias} (${this.info.padrillo.plate})`
					: this.info.padrillo.plate
			} - ${
				this.info.madrilla.alias
					? `${this.info.madrilla.alias} (${this.info.madrilla.plate})`
					: this.info.madrilla.plate
			} `
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},

		async getReasons() {
			this.isPreloading()
			try {
				const { data } = await unbornReasonsService.getReasons()
				this.reasons = data.data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async getExistingReasons() {
			console.log("existing")

			this.isPreloading()
			try {
				const { data } = await unbornReasonsService.getUnbornReasons(this.info.el_id)
				this.existingReasons = data.data

				if (this.existingReasons.length > 0) {
					this.reasonsData = this.reasonsData.map((item, index) => {
						return { reason: this.existingReasons[index] ?? item }
					})
					console.log("reasons:", this.reasonsData)
					console.log("existing:", this.existingReasons)
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async save() {
			if (!(await this.$refs.form.validate())) return

			this.isPreloading()
			try {
				const res = await unbornReasonsService.storeUnbornReasons({
					reasons: this.reasonsData.map((el) => el.reason),
					egg_lote_id: this.info.el_id,
				})
				this.$emit("refresh")
				this.handleHidden()
				this.showSuccessToast(res.message)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},

	async mounted() {
		this.toggleModal("unborn-reasons-modal")
		console.log(this.info.n_nac)

		if (typeof this.info.n_nac === "number") {
			this.reasonsData = Array.from({ length: this.info.n_nac }, () => ({ reason: null }))
		}

		if (this.info.n_nac_motives_qty > 0) {
			await this.getExistingReasons()
		}
	},
}
</script>

<style lang="scss" scoped></style>
