import axios from "@/axios"

const url = "/api/tournament"

class TournamentService {
	async getTournaments({ start_date, end_date }) {
		const data = await axios.get(`${url}`, {
			params: {
				start_date,
				end_date,
			},
		})
		return data
	}

	async store(body) {
		try {
			const data = await axios.post(`${url}/store`, body)
			return data
		} catch (error) {
			console.log("error", error)
		}
	}
	async createEventMassive({ events }) {
		const data = await axios.post(`${url}/events/store-massive`, {
			events,
		})
		return data
	}
	async getCollaboratorRoles() {
		const data = await axios.get(`${url}/roles`)
		return data
	}
	async getCollaborators() {
		const data = await axios.get(`${url}/roles/get-users`)
		return data
	}
	async getTournamentById(id) {
		try {
			const { data } = await axios.get(`${url}/${id}`)
			return data
		} catch (error) {
			return {}
		}
	}
	async getTournamentOpts({ start_date, end_date }) {
		const data = await axios.get(`${url}/current-tournaments`, {
			params: {
				start_date,
				end_date,
			},
		})
		return data
	}
	async getTournamentTypes() {
		const data = await axios.get(`${url}/tournament-types`)
		return data
	}
	async getColiseumByTournament({ tournament_id }) {
		const data = await axios.get(`${url}/tournament-coliseums/${tournament_id}`)
		return data
	}
	async getStagesByTournament({ tournament_id }) {
		const data = await axios.get(`${url}/tournament-stages/${tournament_id}`)
		return data
	}
	async uploadVideoConfrontation(videos) {
		const { data } = await axios.post(`/api/tournament/events/update-video-tournament`, { videos })
		return data
	}
	async getEvents({
		start_temporary_date,
		end_temporary_date,
		order,
		orderBy,
		tournament_id,
		coliseum_id,
		p_stage_id,
		p_type_id,
		method_id,
		start_date,
		end_date,
		status_id,
		min_amount,
		max_amount,
		week_from,
		week_to,
	}) {
		const data = await axios.get(`${url}/events`, {
			params: {
				start_temporary_date,
				end_temporary_date,
				order,
				orderBy,
				tournament_id,
				coliseum_id,
				p_stage_id,
				p_type_id,
				method_id,
				start_date,
				end_date,
				status_id,
				min_amount,
				max_amount,
				week_from,
				week_to,
			},
		})
		return data
	}
	async searchSpecimens({ search }) {
		const data = await axios.get(`${url}/specimens-available`, {
			params: { search },
		})
		return data
	}
	async getEventFronts({ event_id }) {
		const data = await axios.get(`${url}/events/get-fronts/${event_id}`)
		return data
	}
	async saveFronts({ event_id, fronts }) {
		const data = await axios.post(`${url}/events/store-fronts`, {
			event_id,
			fronts,
		})
		return data
	}
	async updateDetailsTournament({ body, id }) {
		try {
			const { data } = await axios.put(`${url}/update-details-tournament/${id}`, {
				...body,
			})
			return data
		} catch (error) {
			return error.response
		}
	}
	async storeSpecimensEvent({ body }) {
		try {
			const { data } = await axios.post(`${url}/events/store-specimens`, {
				...body,
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async getSpecimensToEvent({ id, search }) {
		try {
			const { data } = await axios.get(`${url}/events/get-specimens/${id}`, {
				params: {
					search,
				},
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async AddPerDiemToEvent({ event_id, per_diems, status }) {
		try {
			const { data } = await axios.post(`${url}/events/add-per-diem`, {
				event_id,
				per_diems,
				status,
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async getPerDiemsByEvent({ id }) {
		try {
			const { data } = await axios.get(`${url}/events/get-per-diems/${id}`)
			return data
		} catch (error) {
			return error.response
		}
	}

	async getAmountRange() {
		const { data } = await axios.get(`${url}/events/get-max-amount`)
		return data
	}
	async updateResponsible({ event_id, responsible_id }) {
		const { data } = await axios.put(`${url}/events/update-responsible/${event_id}`, {
			responsible_id,
		})
		return data
	}
	async updateCollaborators({ event_id, collaborators }) {
		const { data } = await axios.put(`${url}/events/update-collaborators/${event_id}`, {
			collaborators,
		})
		return data
	}
	async getCounters({ start_temporary_date, end_temporary_date, start_week, end_week }) {
		const { data } = await axios.get(`${url}/events/get-count-by-status`, {
			params: { start_temporary_date, end_temporary_date, start_week, end_week },
		})
		return data
	}

	async getRivals() {
		const { data } = await axios.get(`${url}/events/rivals`)
		return data
	}

	async saveResults({ body }) {
		const { data } = await axios.post(`${url}/events/save-results`, body)
		return data
	}

	async getCollaboratorsByEventId({ id }) {
		const { data } = await axios.get(`${url}/events/get-collaborators/${id}`)
		return data
	}

	async cancelFront({ id }) {
		const { data } = await axios.delete(`${url}/events/cancel-front/${id}`)
		return data
	}

	async updateEventStatus({ event_id, status }) {
		try {
			const { data } = await axios.put(`${url}/events/update-status/${event_id}`, {
				status,
			})
			return data
		} catch (error) {
			return error
		}
	}
	async deleteSpecimenFromEvent({ specimen_id, tournament_event_id, recommended_id }) {
		const { data } = await axios.post(`${url}/events/delete-specimen-by-event`, {
			specimen_id,
			tournament_event_id,
			recommended_id,
		})
		return data
	}

	async deleteEventFrontSpecimen({ confrontation_id, tefs_id, front_id }) {
		const { data } = await axios.post(`${url}/events/delete-event-front-specimen`, {
			confrontation_id,
			tefs_id,
			front_id,
		})
		return data
	}
	async updateTournamentName({ tournament_id, name }) {
		const { data } = await axios.put(`${url}/update-name/${tournament_id}`, {
			name,
		})
		return data
	}

	async setToCancelTournament({ id }) {
		const { data } = await axios.put(`${url}/set-to-cancel/${id}`)
		return data
	}

	async getPendingEvents() {
		const { data } = await axios.get(`${url}/events/get-tournament-event-pending`)
		return data
	}

	async getPendingEventsCounter() {
		const { data } = await axios.get(`${url}/events/get-count-tournament-event-pending`)
		return data
	}
	async getSubStages({ tournament_id, stage }) {
		const { data } = await axios.get(`${url}/tournament-sub-stages/${stage}`, {
			params: { tournament_id },
		})
		return data
	}

	async updateStagesByTournament({ tournament_id, stages }) {
		const { data } = await axios.post(`${url}/update-stages-by-tournament`, {
			tournament_id,
			stages,
		})
		return data
	}

	async addAwards({ awards, tournament_id, tournament_event_front_id, award_position_id, description }) {
		const { data } = await axios.post(`${url}/events/add-tournament-awards`, {
			awards,
			tournament_id,
			tournament_event_front_id,
			award_position_id,
			description,
		})
		return data
	}

	async getEventAwards({ event_id, all }) {
		const { data } = await axios.get(`${url}/events/get-awards-by-event/${event_id}`, {
			params: { all: all ? 1 : 0 },
		})
		return data
	}

	async getAwardsItems({ id }) {
		const { data } = await axios.get(`${url}/events/get-awards-items/${id}`)
		return data
	}

	//get-calendar-tournament

	//get-awards-items
	async getSpecimensByTournament({ tournament_id, status_id, event_id }) {
		const { data } = await axios.get(`${url}/events/get-specimens-registered/${tournament_id}`, {
			params: { status_id, event_id },
		})
		return data
	}
	async getSpecimensByTournamentCounters({ tournament_id, event_id }) {
		const { data } = await axios.get(`${url}/events/get-specimens-registered-counter/${tournament_id}`, {
			params: {
				event_id,
			},
		})
		return data
	}
	async getEventsResumeByTournament({ tournament_id }) {
		const { data } = await axios.get(`${url}/events/get-events-tournament-by-resume/${tournament_id}`)
		return data
	}
	async getDetailsDateByCalendar({ date_id }) {
		const { data } = await axios.get(`${url}/events/get-details-date-by-calendar/${date_id}`)
		return data
	}

	//get-details-date-by-calendar
	async getCalendarTournament({ dateIn, dateFn }) {
		const { data } = await axios.get(`${url}/events/get-calendar-tournament`, {
			params: {
				dateIn,
				dateFn,
			},
		})
		return data
	}

	async counterCalendar() {
		const { data } = await axios.get(`${url}/events/counter-calendar`)
		return data
	}

	async getAwardsTournament({ id }) {
		const { data } = await axios.get(`${url}/get-awards-tournament/${id}`)
		return data
	}

	async assignSpecimenAliases({ specimens }) {
		try {
			const { data } = await axios.post(`${url}/events/add-alias-masive-specimens`, { specimens })
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async getEventPayments({ event_id }) {
		const { data } = await axios.get(`${url}/payments/event/${event_id}`)
		return data
	}

	async getPaymentBeneficiaries({ name }) {
		const { data } = await axios.get(`${url}/payments/beneficiaries`, {
			params: { name },
		})
		return data
	}

	// async removeFront({ id }) {
	// 	const { data } = await axios.delete(`${url}/events/delete-front-by-id/${id}`)
	// 	return data
	// }

	async removePaymentEvent({ id }) {
		const { data } = await axios.delete(`${url}/payments/events/${id}`)
		return data
	}

	async removePaymentFront({ id }) {
		const { data } = await axios.delete(`${url}/payments/fronts/${id}`)
		return data
	}

	async upsertFrontsPayment({ fronts, status_id, event_id }) {
		const { data } = await axios.post(`${url}/payments/fronts`, {
			fronts,
			status_id,
			event_id,
		})
		return data
	}

	async upsertEventsPayment({ events }) {
		const { data } = await axios.post(`${url}/payments/events`, {
			events,
		})
		return data
	}

	async addFront({ event_id }) {
		try {
			const { data } = await axios.post(`${url}/events/add-front/${event_id}`)
			return data
		} catch (error) {}
	}

	async removeFront({ event_id }) {
		try {
			const { data } = await axios.post(`${url}/events/remove-front/${event_id}`)
			return data
		} catch (error) {}
	}
	// dashboard
	async getDsGeneralResults({ start_date, end_date }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getDsGeneralList({ start_date, end_date, result_id, page, perpage, search }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-list`, {
			params: { start_date, end_date, result_id, page, perpage, search },
		})
		return data
	}

	async getDsMonthlyResults({ start_date, end_date }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-months`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getDsOriginResults({ start_date, end_date, origin_id }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-by-origin`, {
			params: { start_date, end_date, origin_id },
		})
		return data
	}

	async getFronts({ tournament_id }) {
		const { data } = await axios.get(`${url}/fronts/get-by-tournament`, {
			params: { tournament_id },
		})
		return data
	}
	async getDsTournamentResults({ start_date, end_date, order_by }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-by-tournament`, {
			params: { start_date, end_date, order_by },
		})
		return data
	}

	async getDsParentResults({ start_date, end_date, category_id }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-by-parent`, {
			params: { start_date, end_date, category_id },
		})
		return data
	}

	async getDsTournamentExpenses({ start_date, end_date }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-costs-by-tournament`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getDsMonthlyExpenses({ start_date, end_date }) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-costs-by-month`, {
			params: { start_date, end_date },
		})
		return data
	}

	async updateDate({ date, id }) {
		const { data } = await axios.put(`${url}/events/update-date/${id}`, {
			date,
		})
		return data
	}

	async getDsResultsByRival(params) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-results-by-rival`, {
			params,
		})
		return data
	}

	async getDsResultsByRivalDetails(params) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-results-by-rival-details`, {
			params,
		})
		return data
	}
	async getWinsByAgeRange(params) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-wins-by-age`, {
			params,
		})
		return data
	}
	async getWinsByAgeRangeDetails(params) {
		const { data } = await axios.get(`${url}/dashboard-tournament-general-wins-by-age-details`, {
			params,
		})
		return data
	}
}

export default new TournamentService()
