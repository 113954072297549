<template>
	<b-overlay class="mb-2 card p-2 h-100" :show="isLoading">
		<span class="title align-self-start">RESULTADOS POR RIVAL</span>
		<div class="d-flex flex-column w-100">
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start">
					<label>Mostrar</label>
					<v-select
						v-model="pagination.perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
						style="width: 90px"
						@input="() => getDsResultsByRival()"
					/>
					<label class="mr-2">registros</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="handleRefresh" />
				</b-col>
				<b-col cols="12" xl="5">
					<div class="container-btns-page-specimens">
						<div class="d-flex">
							<b-form-input
								v-model="search"
								class="d-inline-block mr-1"
								placeholder="Rival"
								@keyup.enter="handleInputSearch"
								debounce="500"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-table-simple responsive sticky-header style="height: auto" no-border-collapse>
				<b-thead class="sticky-header" style="white-space: nowrap">
					<b-th class="text-center px-0" style="vertical-align: middle">Rival</b-th>
					<b-th
						@click="() => handleOrder(1, 'desc')"
						class="text-center clickable px-0"
						style="vertical-align: middle"
					>
						<span>Peleas</span>
						<template v-if="order_status[0].is_active">
							<feather-icon
								v-if="order_status[0].direction === 'desc'"
								icon="ChevronDownIcon"
								size="20"
							/>
							<feather-icon v-if="order_status[0].direction === 'asc'" icon="ChevronUpIcon" size="20" />
						</template>
					</b-th>
					<b-th
						@click="() => handleOrder(2, 'desc')"
						class="text-center clickable px-0"
						style="vertical-align: middle"
					>
						<span>Triunfos</span>
						<template v-if="order_status[1].is_active">
							<feather-icon
								v-if="order_status[1].direction === 'desc'"
								icon="ChevronDownIcon"
								size="20"
							/>
							<feather-icon v-if="order_status[1].direction === 'asc'" icon="ChevronUpIcon" size="20" />
						</template>
					</b-th>
					<b-th
						@click="() => handleOrder(3, 'desc')"
						class="text-center clickable px-0"
						style="vertical-align: middle"
					>
						<span>Empates</span>
						<template v-if="order_status[2].is_active">
							<feather-icon
								v-if="order_status[2].direction === 'desc'"
								icon="ChevronDownIcon"
								size="20"
							/>
							<feather-icon v-if="order_status[2].direction === 'asc'" icon="ChevronUpIcon" size="20" />
						</template>
					</b-th>
					<b-th
						@click="() => handleOrder(4, 'desc')"
						class="text-center clickable px-0"
						style="vertical-align: middle"
					>
						<span>Derrotas</span>
						<template v-if="order_status[3].is_active">
							<feather-icon
								v-if="order_status[3].direction === 'desc'"
								icon="ChevronDownIcon"
								size="20"
							/>
							<feather-icon v-if="order_status[3].direction === 'asc'" icon="ChevronUpIcon" size="20" />
						</template>
					</b-th>
				</b-thead>
				<b-tbody>
					<template v-if="rivals.length > 0">
						<b-tr v-for="(rival, index) in rivals" :key="index">
							<b-td class="text-center px-0">
								<span style="font-weight: bold; font-size: 12px">{{ rival.name }}</span>
							</b-td>
							<b-td class="text-center px-0">
								<span
									@click="rival.total != 0 ? openDetailModal(rival) : null"
									:class="{ 'underline-hover': rival.total != 0 }"
								>
									{{ rival.total }}
								</span>
							</b-td>
							<b-td class="text-center text-success px-0">
								<span
									@click="rival.triunfos != 0 ? openDetailModal(rival, 'triunfo') : null"
									:class="{ 'underline-hover': rival.triunfos != 0 }"
								>
									{{ rival.triunfos }}
								</span>
							</b-td>
							<b-td class="text-center text-warning px-0">
								<span
									@click="rival.empates != 0 ? openDetailModal(rival, 'empate') : null"
									:class="{ 'underline-hover': rival.empates != 0 }"
								>
									{{ rival.empates }}
								</span>
							</b-td>
							<b-td class="text-center text-danger px-0">
								<span
									@click="rival.derrotas != 0 ? openDetailModal(rival, 'derrota') : null"
									:class="{ 'underline-hover': rival.derrotas != 0 }"
								>
									{{ rival.derrotas }}
								</span>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="rivals.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
		<ResultDetailsModal v-if="showModal" :rival="rivalSelected" :season="season" @closing="showModal = false" />
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import ResultDetailsModal from "@/views/amg/tournaments/v2/components/results-by-rival/components/ResultByRivalDetailModal.vue"

export default {
	components: { ResultDetailsModal },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		rivals: [],
		pagination: {
			total: 0,
			from: 0,
			to: 0,
			curPage: 1,
			perPage: 25,
		},
		rivalSelected: null,
		perPageOptions: [10, 25, 50],
		search: null,
		showModal: false,
		order_by: 1,
		order_direction: "desc",
		order_status: [
			{
				id: 1,
				name: "enfrentamientos",
				is_active: true,
				direction: "desc",
			},
			{
				id: 2,
				name: "triunfos",
				is_active: false,
				direction: "desc",
			},
			{
				id: 3,
				name: "empates",
				is_active: false,
				direction: "desc",
			},
			{
				id: 4,
				name: "derrotas",
				is_active: false,
				direction: "desc",
			},
		],
	}),
	computed: {},
	methods: {
		handleRefresh() {
			this.resetOrder()
			this.getDsResultsByRival()
		},
		handleInputSearch() {
			this.resetOrder()
			this.getDsResultsByRival()
		},
		resetOrder() {
			this.order_status.forEach((item) => {
				item.is_active = item.id === 1
				item.direction = "desc"
			})
			this.pagination.curPage = 1
			this.order_by = 1
			this.order_direction = "desc"
		},
		handleOrder(orderBy) {
			this.order_status.forEach((item) => {
				if (item.id === orderBy) {
					if (item.is_active) {
						item.direction = item.direction === "desc" ? "asc" : "desc"
					} else {
						item.direction = "desc"
					}
					item.is_active = true
					this.order_direction = item.direction
				} else {
					item.is_active = false
					item.direction = "desc"
				}
			})
			this.order_by = orderBy
			this.getDsResultsByRival()
		},
		openDetailModal(rival, type = null) {
			this.rivalSelected = {
				id: rival.other_fighter_id,
				name: rival.name,
				result: type,
			}
			this.showModal = true
		},
		async getDsResultsByRival(page = 1) {
			const params = {
				search: this.search,
				start_date: this.season.start,
				end_date: this.season.end,
				per_page: this.pagination.perPage,
				page: page,
				order: this.order_by,
				order_direction: this.order_direction,
			}
			try {
				this.isLoading = true
				const { data } = await tournamentService.getDsResultsByRival(params)
				this.rivals = data.data
				this.pagination.curPage = data.current_page
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.error(error)
			} finally {
				this.isLoading = false
			}
		},
	},
	async mounted() {
		await this.getDsResultsByRival()
	},
	watch: {
		"season.start"() {
			this.getDsResultsByRival()
		},
		"pagination.curPage": async function () {
			await this.getDsResultsByRival(this.pagination.curPage)
		},
	},
}
</script>

<style scoped lang="scss">
.title {
	display: block;
	margin-bottom: 2rem;
	color: var(--primary);
	font-size: 1.5rem;
	font-weight: 700;
}

.chadow {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 9rem;
	aspect-ratio: 1/1;
	background: transparent;
	box-shadow: 0 0 0.5rem #000;
	border-radius: 50%;
	transform: translate(-50%, -64%);
}

@media screen and (min-width: 768px) {
	.chadow {
		width: 12rem;
		transform: translate(-50%, -62%);
	}
}

@media screen and (min-width: 1024px) {
	.chadow {
		width: 40%;
		transform: translate(-50%, -98%);
	}
}

@media screen and (min-width: 1440px) {
	.chadow {
		width: 40%;
		transform: translate(-50%, -62%);
	}
}
.underline-hover {
	text-decoration: underline;
	transition: all 0.1s ease;
}

.underline-hover:hover {
	font-weight: bold;
	cursor: pointer;
	font-size: 1.2rem;
}
.container-text {
	background: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 30%;
	width: 30%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	box-shadow: 0 0 21px rgba(0, 0, 0, 0.17);
	@media screen and (min-width: 420px) {
		height: 40%;
		width: 40%;
	}
	@media screen and (min-width: 1024px) {
		height: 40%;
		width: 40%;
	}
	.number {
		font-size: 30px;
		color: #434343;
		font-weight: bolder;
		@media screen and (min-width: 420px) {
			font-size: 40px;
		}
	}
	.text {
		margin-bottom: 0;
		font-size: 16px;
		color: #909090;
		@media screen and (min-width: 420px) {
			font-size: 20px;
		}
	}
}

.container-legend {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0;
	@media screen and (min-width: 1024px) {
		padding: 0 1rem;
	}
	@media screen and (min-width: 1800px) {
		padding: 0 2rem;
	}
	.item-legend {
		display: flex;
		flex-direction: column;
		align-items: center;
		.title {
			color: #909090;
			font-size: 16px;
			font-weight: 500;
			font-size: 12px;
			@media screen and (min-width: 1024px) {
				font-size: 14px;
			}
			@media screen and (min-width: 1800px) {
				font-size: 18px;
			}
		}
		.number {
			color: #434343;
			font-weight: bolder;
			font-size: 12px;
			@media screen and (min-width: 1024px) {
				font-size: 14px;
			}
			@media screen and (min-width: 1800px) {
				font-size: 17px;
			}
		}
	}
}

.container-bars {
	overflow: auto;
	.bars {
		min-width: 1000px;
	}
}
</style>

<style>
.myDonut .apexcharts-legend-series {
	flex-direction: column-reverse;
}

.apexcharts-toolbar {
	background: red !important;
	display: none !important;
}
</style>
