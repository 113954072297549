<template>
	<div class="card mb-0 container-card-dashboard">
		<div class="header p-2">
			<div class="d-flex align-items-center">
				<h1>Sin Nacer</h1>
			</div>
		</div>
		<div class="body p-2">
			<div :key="keyChart" id="chart-unborn">
				<apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
			</div>
		</div>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import encasteDashboardService from "@/services/encaste/encasteReports.service"

export default {
	name: "DashboardUnborn",
	components: {
		apexchart: VueApexCharts,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			series: [
				{
					name: "Funnel Series",
					data: [0, 0, 0],
				},
			],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					dropShadow: {
						enabled: true,
					},
					toolbar: {
						show: false, // Desactiva el menú del chart
					},
				},

				colors: ["#e8dcca", "#24f262", "#f73448"],

				plotOptions: {
					bar: {
						borderRadius: 0,
						horizontal: true,
						barHeight: "80%",
						isFunnel: true,
						distributed: true,
					},
				},
				dataLabels: {
					enabled: true,
					style: {
						colors: ["#000000"],
						fontSize: "16px",
					},
					formatter: function (val, opt) {
						return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
					},
					dropShadow: {
						enabled: false,
					},
				},
				title: {
					text: "",
					align: "middle",
				},
				xaxis: {
					categories: ["Huevos ingresados", "Huevos incubados", "Huevos sin nacer"],
				},
				legend: {
					show: false,
				},
			},
			keyChart: 0,
		}
	},
	async mounted() {
		await this.initChart()
	},
	computed: {},
	methods: {
		async initChart() {
			const res = await encasteDashboardService.getChicksUnborn({
				start_date: this.start_date,
				end_date: this.end_date,
			})

			this.series[0] = {
				name: "",
				data: [res.data.ing, res.data.inc, res.data.no_nac],
			}
			this.keyChart = this.keyChart + 1
		},
	},
	watch: {
		async start_date() {
			await this.initChart()
		},
		async "pagination.page"() {
			await this.initChart()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
}
</style>
