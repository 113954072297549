<template>
	<div>
		<b-modal
			ref="decrestation-tracking-modal"
			title="Seguimiento de descreste"
			no-close-on-backdrop
			centered
			@hidden="closeModal()"
			size="xlg"
			hide-footer
		>
			<div>
				<b-row class="mb-1">
					<b-col class="d-flex align-items-center">
						<small class="text-secondary">
							Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
						</small>
					</b-col>
					<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
						<b-pagination
							v-model="pagination.curPage"
							:total-rows="pagination.total"
							:per-page="pagination.perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="pagination.perPage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
							style="width: 90px"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refreshTable" />
					</b-col>
				</b-row>
				<b-table
					slot="table"
					ref="decrestation-tracking-table"
					id="decrestation-tracking-table"
					sticky-header="50vh"
					small
					show-empty
					no-provider-filtering
					:busy.sync="isBusy"
					responsive="sm"
					:items="myProvider"
					:fields="fields"
					:per-page="pagination.perPage"
					:current-page="pagination.curPage"
					:filter="filter"
					:has-provider="true"
					:class="['text-center']"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle mr-1" />
							<strong>Loading ...</strong>
						</div>
					</template>
					<template #cell(created_at)="{ item }">
						{{ item.created_at | myGlobalDayShort }}
					</template>
					<template #cell(created_by)="{ item }">
						<span class="text-uppercase">
							{{ item.user.name }}
						</span>
					</template>
					<template #cell(quantity)="row">
						<div @click="row.toggleDetails" class="cursor-pointer">
							<b-badge variant="info" style="width: 50px">
								{{ row.item.quantity }}
							</b-badge>
							<feather-icon
								class="text-success"
								:icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
								size="21"
							/>
						</div>
					</template>
					<template #row-details="row">
						<b-card class="m-0 p-0 custom-card">
							<div class="d-flex flex-wrap justify-content-center">
								<div
									v-for="specimen in row.item.specimens"
									:key="specimen.id"
									class="d-flex align-items-center m-1 custom-item"
								>
									<SpecimenPlate :specimen="specimen" />
								</div>
							</div>
						</b-card>
					</template>
				</b-table>
			</div>
		</b-modal>
	</div>
</template>
<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import decrestService from "@/services/header/decrest.service"
import modalMixin from "@/mixins/modal.js"
export default {
	name: "DecrestationTrackingModal",
	mixins: [modalMixin],
	props: {},
	components: { SpecimenPlate },
	data() {
		return {
			filter: [],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
			perPageOptions: [10, 20, 50],
			items: [],
			isBusy: false,
			fields: [
				{
					key: "created_at",
					label: "Fecha",
					visible: true,
				},
				{
					key: "created_by",
					label: "Creado por",
					visible: true,
				},
				{
					key: "quantity",
					label: "Cantidad",
					visible: true,
				},
			],
		}
	},
	computed: {},
	watch: {},
	async mounted() {
		this.toggleModal("decrestation-tracking-modal")
	},
	async created() {},
	methods: {
		refreshTable() {
			this.$refs["decrestation-tracking-table"].refresh()
		},
		closeModal() {
			this.$emit("closeModal")
		},
		async myProvider() {
			try {
				const params = {
					per_page: this.pagination.perPage,
					page: this.pagination.curPage,
				}
				const { data } = await decrestService.getDecrestationTracking(params)

				this.items = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
				return this.items
			} catch (e) {
				console.log(e)
				this.showToast("danger", "top-right", "Error", "XIcon", e.message)
				return []
			}
		},
	},
}
</script>
<style scoped lang="scss">
.custom-row {
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border: 2px solid #3498db;
	padding: 10px 0;
	margin: 10px 0;
	.col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
.custom-header-row {
	border-radius: 5px;
	background-color: #8f5fe8;
	color: #ffffff;
	padding: 10px 0;
	margin: 10px 0;
}
.custom-audio {
	transform: scale(0.8) !important;
	border-width: 2px !important;
	max-width: 380px;
	min-width: 380px;
}
.custom-card {
	border: 3px solid var(--primary);
	border-radius: 10px;
	padding: 1rem;
}
.custom-item {
	border: 2px solid var(--primary);
	border-radius: 10px;
	padding: 0.5rem;
}
</style>
