import axios from "@/axios"

const url = "/api/brain/specimens/qualities/"
const url2 = "/api/specimens/details/qualities/"

class QualitiesService {
	async getQualities({ is_active, specimen_category_id, type }) {
		try {
			const data = await axios.get(`${url}`, {
				params: {
					is_active,
					specimen_category_id,
					type,
				},
			})
			return data
		} catch (error) {
			throw error
		}
	}

	async storeQuality({ specimen_category_id, name, type }) {
		try {
			const { data } = await axios.post(`${url}`, {
				specimen_category_id,
				name,
				type,
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async editQuality({ id, name }) {
		try {
			const data = await axios.put(`${url}${id}`, { name })
			return data
		} catch (error) {
			throw error
		}
	}

	async deleteQuality({ id }) {
		try {
			const data = await axios.delete(`${url}${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async updateStatus(id) {
		try {
			const data = await axios.put(`${url}switch-active/${id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async storeQualitySpecimen({ qualities, specimen_id,type }) {
		try {
			const { data } = await axios.post(`${url2}store/`, {
				qualities,
				specimen_id,
				type,
			})
			return data
		} catch (error) {
			return error.response
		}
	}

	async getCounters() {
		try {
			const data = await axios.get(`${url}counters`)
			return data
		} catch (error) {
			throw error
		}
	}
}

export default new QualitiesService()
