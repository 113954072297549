<template>
	<div>
		<b-overlay :show="isLoading">
			<b-tabs>
				<b-tab @click="tab = 0" :active="tab === 0">
					<template #title>
						Cintillo en pata
						<b-badge variant="danger" class="ml-1">{{ selectedMonth.pata_qty }}</b-badge>
					</template>
				</b-tab>
				<b-tab @click="tab = 1" :active="tab === 1">
					<template #title>
						Cintillo en ala
						<b-badge variant="danger" class="ml-1">{{ selectedMonth.ala_qty }}</b-badge>
					</template>
				</b-tab>
				<!-- <b-tab @click="tab = 2" :active="tab === 2"> 
					<template #title>
						Placa
						<b-badge variant="danger" class="ml-1">{{ selectedMonth.specimen_qty }}</b-badge>
					</template>
				</b-tab> -->
			</b-tabs>
			<div v-if="tab === 2" class="d-flex justify-content-center gap-x-2 mb-3">
				<b-button
					:variant="!selectedYear ? 'primary' : 'outline-primary'"
					@click="getDeadSpecimenQtyByMonthOfBirthDetails()"
				>
					Todos
					<b-badge variant="danger">{{ selectedMonth.specimen_qty }}</b-badge>
				</b-button>
				<b-button
					v-for="(counter, index) in counters"
					:key="index"
					:variant="counter.active ? 'primary' : 'outline-primary'"
					@click="getDeadSpecimenQtyByMonthOfBirthDetails(counter.year, index)"
				>
					{{ counter.year }}
					<b-badge variant="danger">{{ counter.qty }}</b-badge>
				</b-button>
			</div>
			<div class="pie-container">
				<VueApexCharts type="pie" height="300" :options="chartOpts" :series="chartData" />
				<div>
					<div
						v-for="result in results"
						:key="result.reason.reason_id"
						class="item-count"
						@click="handleLabelClick(result)"
					>
						<p class="mb-0">
							{{ `${result.reason.toUpperCase()} : ${result.qty}` }}
						</p>
					</div>
				</div>
			</div>
		</b-overlay>
		<DeadPataByReasonModal
			v-if="showPataDetails"
			:start_date="year.start_date"
			:end_date="year.end_date"
			:selectedReason="selectedReason"
			:selectedMonth="selectedMonth"
			@closing="showPataDetails = false"
		/>
		<DeadAlaByReasonModal
			v-if="showAlaDetails"
			:start_date="year.start_date"
			:end_date="year.end_date"
			:selectedReason="selectedReason"
			:selectedMonth="selectedMonth"
			@closing="showAlaDetails = false"
		/>
		<DeadSpecimenByReasonModal
			v-if="showSpecimenDetails"
			:start_date="year.start_date"
			:end_date="year.end_date"
			:selectedReason="selectedReason"
			:selectedMonth="selectedMonth"
			:selectedYear="selectedYear"
			@closing="showSpecimenDetails = false"
		/>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import specimensService from "@/services/specimens.service"
import DeadPataByReasonModal from "@/views/amg/encaste/dashboard-encaste/components/mortality-by-dob-month/DeadPataByReasonModal.vue"
import DeadAlaByReasonModal from "@/views/amg/encaste/dashboard-encaste/components/mortality-by-dob-month/DeadAlaByReasonModal.vue"
import DeadSpecimenByReasonModal from "@/views/amg/encaste/dashboard-encaste/components/mortality-by-dob-month/DeadSpecimenByReasonModal.vue"
export default {
	components: { VueApexCharts, DeadPataByReasonModal, DeadAlaByReasonModal, DeadSpecimenByReasonModal },
	props: {
		year: Object,
		selectedMonth: Object,
	},
	data() {
		return {
			tab: 0,
			isLoading: true,
			chartData: [44, 55, 13, 43, 22],
			showAlaDetails: false,
			showPataDetails: false,
			showSpecimenDetails: false,
			selectedReason: null,
			chartOpts: {
				chart: {
					type: "pie",

					events: {
						dataPointSelection: (event, chartContext, config) => {
							const clickedIndex = config.dataPointIndex
							this.selectedReason = this.results[clickedIndex]
							if (this.tab === 0) this.showPataDetails = true
							if (this.tab === 1) this.showAlaDetails = true
							if (this.tab === 2) this.showSpecimenDetails = true
						},
					},
				},
				labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
				colors: [
					"#001219",
					"#005F73",
					"#0A9396",
					"#94D2BD",
					"#E9D8A6",
					"#E9D8A6",
					"#CA6702",
					"#BB3E03",
					"#AE2012",
					"#9B2226",
				],
				fill: {
					type: "gradient",
				},
				legend: {
					show: false,
				},
			},
			counters: [],
			selectedYear: null,
			results: [],
		}
	},
	methods: {
		handleLabelClick(result) {
			this.selectedReason = result
			if (this.tab === 0) this.showPataDetails = true
			if (this.tab === 1) this.showAlaDetails = true
			if (this.tab === 2) this.showSpecimenDetails = true
		},
		async getDeadSpecimenQtyByMonthOfBirthDetails(year = null) {
			this.isLoading = true
			this.selectedYear = year
			try {
				const { data } = await specimensService.getDeadSpecimenQtyByMonthOfBirthDetails({
					start_date: this.year.start_date,
					end_date: this.year.end_date,
					month: Number(this.selectedMonth.month),
					year,
				})
				const { results, totals } = data
				this.results = results
				this.counters = totals.map((counter) => ({ ...counter, active: counter.year === year }))
				this.chartData = results.map((d) => d.qty)
				this.chartOpts = {
					labels: results.map((d) => d.reason.toUpperCase()),
				}
			} catch (error) {
				console.error(error)
			} finally {
				this.isLoading = false
			}
		},
		async getDeadAlaQtyByDobReason() {
			try {
				this.isLoading = true
				const { data } = await specimensService.getDeadAlaQtyByDobReason({
					start_date: this.year.start_date,
					end_date: this.year.end_date,
					month: Number(this.selectedMonth.month),
				})
				this.results = data
				this.chartData = data.map((d) => Number(d.qty))
				this.chartOpts = {
					labels: data.map((d) => d.reason.toUpperCase()),
				}
			} catch (error) {
				console.log(error)
				this.showToast("danger", "top-right", "Cuidado!", "AlertCircleIcon", error.message)
			} finally {
				this.isLoading = false
			}
		},
		async getDeadPataQtyByDobReason() {
			try {
				this.isLoading = true
				const { data } = await specimensService.getDeadPataQtyByDobReason({
					start_date: this.year.start_date,
					end_date: this.year.end_date,
					month: Number(this.selectedMonth.month),
				})
				this.results = data
				this.chartData = data.map((d) => Number(d.qty))
				this.chartOpts = {
					labels: data.map((d) => d.reason.toUpperCase()),
					colors: [
						"#001219",
						"#005F73",
						"#0A9396",
						"#94D2BD",
						"#E9D8A6",
						"#E9D8A6",
						"#CA6702",
						"#BB3E03",
						"#AE2012",
						"#9B2226",
					],
				}
			} catch (error) {
				console.log(error)
				this.showToast("danger", "top-right", "Cuidado!", "AlertCircleIcon", error.message)
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		tab(val) {
			switch (val) {
				case 0:
					this.getDeadPataQtyByDobReason()
					break
				case 1:
					this.getDeadAlaQtyByDobReason()
					break
				case 2:
					this.getDeadSpecimenQtyByMonthOfBirthDetails()
					break
			}
		},
	},
	mounted() {
		if (this.selectedMonth.pata_qty > 0) {
			this.getDeadPataQtyByDobReason()
		} else if (this.selectedMonth.ala_qty > 0) {
			this.tab = 1
		}
	},
}
</script>

<style lang="scss" scoped>
.pie-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4rem;
	@media screen and (min-width: 1200px) {
		flex-direction: row;
	}
}
.item-count {
	cursor: pointer;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem 0.4rem;
	border-radius: 4px;
	width: auto;
	margin-right: 0.5rem;
	background-color: #dae1ff;
	color: #466aff;
	@media screen and (min-width: 600px) {
		padding: 0.5rem 1rem;
		margin-top: 2rem;
	}
	p {
		font-weight: bold;
		font-size: 12px;
		text-align: center;
	}
	&:first-child {
		margin-top: 0;
	}
}
</style>
