<template>
	<b-modal
		ref="result-by-rival-detail"
		:title="`Resultados | ${rival.name}`"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xlg"
		hide-footer
	>
		<div>
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
					<label>Mostrar</label>
					<v-select
						v-model="pagination.perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
						style="width: 90px"
					/>
					<label class="mr-2">registros</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
				</b-col>
			</b-row>
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header" style="white-space: nowrap">
					<b-th class="text-center">Ejemplar</b-th>
					<b-th class="text-center">Torneo</b-th>
					<b-th class="text-center">Etapa</b-th>
					<b-th class="text-center">Fecha</b-th>
					<b-th class="text-center">Resultado</b-th>
					<b-th class="text-center" style="z-index: 9999">Video</b-th>
				</b-thead>
				<b-tbody>
					<template v-if="confrontations.length > 0">
						<b-tr v-for="(confrontation, index) in confrontations" :key="index">
							<b-td class="text-center">
								<SpecimenPlate :specimen="confrontation.specimen" show_status />
							</b-td>
							<b-td class="text-center">
								<div>
									<ViewImage
										:specimen="{
											id: index,
											url: confrontation.torneo.url_image,
										}"
									/>
								</div>
								<small>{{ confrontation.torneo.name }}</small>
							</b-td>
							<b-td class="text-center">
								<b-badge :style="{ background: confrontation.torneo.stage_color }">
									{{ confrontation.torneo.stage }}
								</b-badge>
							</b-td>
							<b-td class="text-center">
								<span>{{ confrontation.date | myGlobalDayShort }}</span>
							</b-td>
							<b-td class="text-center">
								<b-badge :variant="variantResult[confrontation.result]" class="text-uppercase">
									{{ confrontation.result }}
								</b-badge>
							</b-td>
							<b-td class="text-center">
								<div
									v-if="confrontation.video.status == 2"
									@click="ValidStatusVideo(confrontation)"
									class="cursor-pointer"
								>
									<b-avatar
										:src="confrontation.video.thumb"
										rounded
										size="80"
										icon="film"
										badge
										badge-variant="danger"
									>
										<template #badge>
											{{ confrontation.video.duration || "-" }}
										</template>
									</b-avatar>
								</div>
								<div v-else>
									<b-badge variant="danger">Sin video</b-badge>
								</div>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="confrontations.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</b-modal>
</template>

<script>
import videosService from "@/services/videos.service"
import { mapActions } from "vuex"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import tournamentService from "@/services/tournament/default.service"
import ViewImage from "@/components/commons/ViewImage.vue"
export default {
	components: { SpecimenPlate, ViewImage },
	props: {
		rival: Object,
		season: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			confrontations: [],
			perPageOptions: [10, 25, 50],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
			variantResult: {
				triunfo: "success",
				empate: "warning",
				derrota: "danger",
			},
		}
	},
	async created() {
		await this.getDetails()
	},
	computed: {},
	methods: {
		...mapActions("header_book", ["toggleModal", "setIndexImage", "setTextImage", "setImages"]),
		async ValidStatusVideo(row) {
			const { data: valid } = await videosService.validStatusVideo({
				id: row.video.uid,
			})
			if (valid.valid) {
				this.showTopeVideo(row)
			} else {
				this.showToast("warning", "top-right", "Resultados", "WarningIcon", "El video no esta listo todavia")
			}
		},
		showTopeVideo(row) {
			this.setImages([
				{
					...row.video,
					type: "video",
					url: row.video.uid,
					text: `${row.specimen.alias ? row.specimen.alias : ""} (${row.specimen.plate}) VS ${
						this.rival.name
					}`,
					specimen: { id: row.specimen.id },
				},
			])
			this.setIndexImage(0)
			this.toggleModal()
		},
		async refresh() {
			await this.getDetails()
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getDetails(page = 1) {
			this.isPreloading()
			try {
				const { data } = await tournamentService.getDsResultsByRivalDetails({
					per_page: this.pagination.perPage,
					page: page,
					fighter_id: this.rival.id,
					result: this.rival.result,
					start_date: this.season.start,
					end_date: this.season.end,
				})
				this.confrontations = data.data
				this.pagination.curPage = data.current_page
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getDetails(this.pagination.curPage)
		},
		"pagination.perPage": async function () {
			await this.getDetails()
		},
	},
	async mounted() {
		this.$refs["result-by-rival-detail"].toggle()
	},
}
</script>

<style>
.label-filter {
	font-size: 0.8rem;
}

.nav.nav-tabs {
	flex-wrap: nowrap;
	overflow-x: auto;
	padding-bottom: 0.5rem;
}

.nav-link {
	white-space: nowrap;
}
</style>
